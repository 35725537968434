<template>
  <div>
    <NavigationBar @openNotificationSidebar="isNotificationSidebarOpen = true" />
    <NotificationSidebar class="z-50" :is-sidebar-open="isNotificationSidebarOpen" @close="isNotificationSidebarOpen = false" />
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script lang="js">
import NavigationBar from "./components/Navigation/NavigationBar.vue";
import { useStore } from 'vuex';
import {onMounted, ref} from 'vue';
import NotificationSidebar from "@/components/Navigation/NotificationSidebar.vue";

export default {
  name: 'App',
  components: {
    NotificationSidebar,
    NavigationBar,
  },
  setup() {
    const store = useStore();
    const isNotificationSidebarOpen = ref(false);

    onMounted(() => {
      store.dispatch('authentication/fetchUserMetadata');
      store.dispatch('wishlist/fetchWishlists');
    });

    return {
      isNotificationSidebarOpen
    }
  }
}
</script>

<style lang="scss">
body {
  background-image: url("~@/assets/whiteMarble.webp");
}

.firebase-emulator-warning { display: none; }
</style>
