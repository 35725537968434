import {
    createUserWithEmailAndPassword,
    getAuth,
    signInWithEmailAndPassword,
    signInWithPopup,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateProfile,
    updateEmail
} from "firebase/auth";
import {
    doc, getDoc, getFirestore, setDoc, updateDoc,
    onSnapshot, query, collection, where, getDocs,
    arrayUnion, deleteDoc, arrayRemove
} from "firebase/firestore";
import {
    getStorage, ref, uploadBytes, getDownloadURL, deleteObject
} from "firebase/storage";
import router from "@/plugins/router";
import {authErrors} from "@/plugins/errorMessageHelper"
import UserMetadata from '@/entities/UserMetadata.js';
import {AuthActions} from "@/Enums/AuthActions";
import store from "@/store/store";
import {toast} from "vue3-toastify";

function signInWithProvider(provider) {
    provider.setCustomParameters({
        'display': 'popup'
    });
    return signInWithPopup(getAuth(), provider)
        .catch((error) => this.commit('setError', error.message))
        .finally(() => redirectUser('/'));
}

function getURL(url) {
    const baseUrl = "https://firebasestorage.googleapis.com/v0/b/gimmy-48951.appspot.com/o/";
    let imagePath = url.replace(baseUrl, "");
    const indexOfEndPath = imagePath.indexOf("?");
    imagePath = imagePath.substring(0, indexOfEndPath);
    imagePath = imagePath.replace("%2F", "/");
    return imagePath;
}

function createSearchIndex(user) {
    const arr = user.queryName.split('');
    const searchableIndex = {}
    const keys = []

    let prevKey = '';

    for (const char of arr) {
        const key = prevKey + char;
        searchableIndex[key] = true
        prevKey = key
        keys.push(key)
    }

    return keys
}

function redirectUser(path) {
    router.push(path).catch(console.error)
}

const authenticationModule = {
    namespaced: true,
    state: () => ({
        firebaseUser: null,
        userMetadata: {},
        userMetadataUpdateListener: null,
        isLoading: false,
        friendsMetadataObjects: [],
        error: null
    }),
    mutations: {
        setFirebaseUser(state, user) {
            state.firebaseUser = user
        },
        setError(state, error) {
            state.isLoading = false
            state.error = error
        },
        updateUserMetadata(state, user) {
            state.userMetadata.firstName = user.firstName
            state.userMetadata.lastName = user.lastName
            state.userMetadata.username = user.username
            state.userMetadata.email = user.email
            state.userMetadata.birthday = user.birthday
            state.userMetadata.bio = user.bio
            state.userMetadata.showRealName = user.showRealName
            state.userMetadata.showBirthday = user.showBirthday
            state.userMetadata.isPublic = user.isPublic
            state.userMetadata.isFriendsListPublic = user.isFriendsListPublic
        },
        setCurrentUserMetadata(state, userMetadata) {
            state.userMetadata = userMetadata
        },
        setFriendsMetadataObjects(state, friends) {
            state.friendsMetadataObjects = friends
        },
        setProfileURL(state, url) {
            state.userMetadata.photoURL = url
        },
        setCoverURL(state, url) {
            state.userMetadata.coverURL = url
        },
        setIsLoading(state, loading) {
            state.isLoading = loading
        }
    },
    actions: {
        async firebaseAuthenticationHandler({commit, dispatch}, {action, params}) {
            commit("setIsLoading", true)
            try {
                let result;
                // check if username is unique
                if (action === AuthActions.CREATE_USER_WITH_EMAIL_AND_PASSWORD) {
                    const isUnique = await dispatch("firebaseCheckIfUsernameIsUnique", params.user.username);
                    if (!isUnique) {
                        commit("setError", "Username is already taken");
                        commit("setIsLoading", false);
                        return;
                    }
                }
                switch (action) {
                    case AuthActions.SIGN_IN_WITH_PROVIDER:
                        result = await signInWithProvider(params.provider);
                        break;
                    case AuthActions.SIGN_IN_WITH_EMAIL_AND_PASSWORD:
                        result = await signInWithEmailAndPassword(getAuth(), params.email, params.password);
                        break;
                    case AuthActions.CREATE_USER_WITH_EMAIL_AND_PASSWORD:
                        result = await createUserWithEmailAndPassword(getAuth(), params.user.email, params.password);
                        await commit("setCurrentUserMetadata", new UserMetadata().fromFirebaseDoc(params.user));
                        await dispatch("updateUserMetadata");
                        break;
                }
                commit("setError", "");
                try {
                    if (AuthActions.CREATE_USER_WITH_EMAIL_AND_PASSWORD === action) {
                        await updateProfile(result.user, {displayName: params.user.username});
                    }
                    commit("setFirebaseUser", result.user);
                    dispatch("fetchUserMetadata");
                    // Fetch the user's wishlists or create a new one if it does not exist.
                    await store.dispatch("wishlist/fetchWishlists");
                    router.push('/').catch((err) => commit("setError", err.message));
                    commit("setError", "");
                    commit("setIsLoading", false);
                } catch (error) {
                    console.error(error);
                    commit("setError", authErrors[error.code.substring(error.code.indexOf('/') + 1)]);
                    dispatch("firebaseSignOut");
                    commit("setIsLoading", false);
                }
            } catch (error) {
                console.error(error);
                commit("setError", authErrors[error.code.substring(error.code.indexOf('/') + 1)]);
                commit("setIsLoading", false);
            }
        },
        async firebaseSignOut({commit}) {
            try {
                await getAuth().signOut();
                redirectUser('/home');
            } catch (error) {
                console.error(error);
                commit('setError', authErrors[error.code.substring(error.code.indexOf('/') + 1)]);
                commit("setIsLoading", false);
            }
        },
        async deletePhoto({state}, {photoURL}) {
            if (state) deleteObject(ref(getStorage(), getURL(photoURL))).then().catch(console.error)
        },
        async deleteAccount({commit, dispatch, state}) {
            try {
                // Delete the user's profile photo
                if (state.userMetadata.photoURL) await dispatch("deletePhoto", {photoURL: state.userMetadata.photoURL})
                // Delete the user's cover photo
                if (state.userMetadata.coverURL) await dispatch("deletePhoto", {photoURL: state.userMetadata.coverURL})
                // Delete Wishlists
                await store.dispatch("wishlist/deleteWishlists", {
                    wishlists: store.state.wishlist.wishlistMetadata.wishlists
                })
                const wishlistDoc = doc(getFirestore(), "wishlists", state.userMetadata.uid);
                await deleteDoc(wishlistDoc).then().catch(console.error)
                // Remove UID from friends' friends list
                for (const friendUID of state.userMetadata.friends) {
                    const friendDoc = doc(getFirestore(), "users", friendUID);
                    await updateDoc(friendDoc, {
                        friends: arrayRemove(state.userMetadata.uid)
                    }).catch(console.error)
                }
                // Delete the user's document in the "users" collection
                const userDoc = doc(getFirestore(), "users", state.userMetadata.uid);
                await deleteDoc(userDoc).then().catch(console.error)
                // Delete the user's account
                await getAuth().currentUser.delete().catch(console.error);
                toast.success('Account deleted successfully', {
                    position: 'bottom-right',
                    autoClose: 2000
                });
                commit("setFirebaseUser", null);
                commit("setCurrentUserMetadata", {});
                commit("setError", "");
                commit("setIsLoading", false);
                redirectUser('/home');
            } catch (error) {
                console.error(error);
                toast.error('Failed to delete account', {
                    position: 'bottom-right',
                    autoClose: 2000
                });
                commit("setError", authErrors[error.code.substring(error.code.indexOf('/') + 1)]);
                commit("setIsLoading", false);
            }
        },
        /**
         * Fetch the user metadata from the "users" collection.
         * This is the main user data that is used throughout the application.
         * @param dispatch - Vuex dispatch function
         * @param state - Vuex state object
         * @param commit - Vuex commit function
         * @returns {Promise<void>} - Promise that resolves when the user metadata has been fetched.
         */
        async fetchUserMetadata({dispatch, state, commit}) {
            try {
                if (state.firebaseUser === null) {
                    return;
                }
                const docResult = await getDoc(doc(getFirestore(), "users", state.firebaseUser.uid));
                const data = docResult.data();
                let userMetadata = new UserMetadata();
                if (data) {
                    userMetadata = userMetadata.fromFirebaseDoc(data);
                    commit("setCurrentUserMetadata", userMetadata);
                    dispatch("listenForUserMetadataChanges");
                    dispatch("fetchFriends");
                    // Check if the user was redirected to the login page
                    if (router.currentRoute.value.query.redirectUrl && router.currentRoute.value.query.redirectUrl !== '/') {
                        window.location = router.resolve(router.currentRoute.value.query.redirectUrl).href
                    }
                } else {
                    let updatedPhotoURL = state.firebaseUser.photoURL?.includes("googleusercontent") ? state.firebaseUser.photoURL.replace("s96", "s600") :
                        state.firebaseUser.photoURL?.includes("graph.facebook") ? `${state.firebaseUser.photoURL}?type=large` : state.firebaseUser.photoURL;
                    userMetadata.username = state.firebaseUser.displayName ?? '';
                    userMetadata.photoURL = updatedPhotoURL;
                    userMetadata.email = state.firebaseUser.email ?? '';
                    userMetadata.uid = state.firebaseUser.uid;
                    userMetadata.birthday = state.userMetadata.birthday ?? '';
                    userMetadata.firstName = state.userMetadata.firstName ?? '';
                    userMetadata.lastName = state.userMetadata.lastName ?? '';
                    commit("setCurrentUserMetadata", userMetadata);
                    await dispatch("updateUserMetadata");
                    dispatch("fetchFriends");
                }
            } catch (err) {
                console.error(err);
                commit("setError", err.message);
            } finally {
                state.isLoading = false;
            }
        },
        async firebaseUpdateProfile({commit, dispatch, state}, {user}) {
            commit("setIsLoading", true);
            try {
                // check if username is unique only if the username has changed
                if (state.firebaseUser.displayName !== user.username) {
                    const isUnique = await dispatch("firebaseCheckIfUsernameIsUnique", user.username);
                    if (!isUnique) {
                        commit("setError", "Username is already taken");
                        commit("setIsLoading", false);
                        return;
                    }
                    // Update the user's profile in Firebase Authentication
                    await updateProfile(state.firebaseUser, {displayName: user.username});
                }
                // update firebase email if it has changed
                if (state.firebaseUser.email !== user.email) {
                    await updateEmail(state.firebaseUser, user.email);
                }
                commit("setFirebaseUser", state.firebaseUser);
                commit("updateUserMetadata", user);
                await dispatch("updateUserMetadata");
                toast.success('Profile updated successfully', {
                    position: 'bottom-right',
                    autoClose: 2000
                });
                commit("setError", "");
            } catch (error) {
                console.error(error);
                commit("setIsLoading", false);
                commit("setError", error.message);
            }
        },
        /**
         * Check if a username is unique in the "users" collection.
         * @param username - String, the username to check
         * @returns {Promise<boolean>} - Promise that resolves with a boolean value.
         */
        //eslint-disable-next-line
        async firebaseCheckIfUsernameIsUnique({}, username) {
            const queryVal = query(collection(getFirestore(), "users"), where("queryName", "==", username.toLowerCase()));
            const docResult = await getDocs(queryVal);
            return docResult.size === 0;
        },
        /**
         * Update user metadata in the "users" collection.
         * @param dispatch - Vuex dispatch function
         * @param state - Vuex state object
         * @param commit - Vuex commit function
         * @param withoutFetch - Boolean, if true, do not fetch the user metadata after the update.
         * @returns {Promise<void>}
         */
        async updateUserMetadata({dispatch, state, commit}, {withoutFetch = false} = {}) {
            const document = doc(getFirestore(), "users", state.firebaseUser.uid);
            const content = state.userMetadata.toJSON();
            content.searchIndex = createSearchIndex(content);
            content.uid = state.firebaseUser.uid
            try {
                await updateDoc(document, content);
                if (!withoutFetch) {
                    commit("setCurrentUserMetadata", state.userMetadata);
                    dispatch("listenForUserMetadataChanges");
                }
            } catch (error) {
                // If the user document does not exist, create it.
                try {
                    await setDoc(document, content);
                    dispatch("fetchUserMetadata");
                } catch (error) {
                    commit("setError", error.message);
                }
            }
        },
        /**
         * Listen for userMetadata changes. This mainly effects things like, new friends and notifications.
         * @param dispatch
         * @param state
         * @param commit
         */
        listenForUserMetadataChanges({dispatch, state, commit}) {
            if (state.userMetadataUpdateListener !== null && state.userMetadataUpdateListener !== undefined) {
                state.userMetadataUpdateListener()
                state.userMetadataUpdateListener = null
            }
            state.userMetadataUpdateListener = onSnapshot(
                doc(getFirestore(), "users", state.firebaseUser.uid),
                (doc) => {
                    const data = doc.data()
                    if (data !== undefined) {
                        // check if a notification has been added
                        if (data.notifications && data.notifications.length > state.userMetadata.notifications.length) {
                            toast("You have a new notification", {
                                position: 'bottom-right',
                                autoClose: 2000
                            })
                        }
                        // User document has been found successfully.
                        commit("setCurrentUserMetadata", new UserMetadata().fromFirebaseDoc(data))
                        dispatch("fetchFriends")
                    }
                }
            )
        },
        fetchFriends({state, commit}) {
            if (state.userMetadata.friends && state.userMetadata.friends.length > 0) {
                const queryVal = query(collection(getFirestore(), "users"), where("uid", "in", state.userMetadata.friends));
                getDocs(queryVal).then((docs) => {
                    let friends = []
                    docs.forEach(doc => {
                        friends.push(doc.data())
                    })
                    commit("setFriendsMetadataObjects", friends)
                })
            }
        },
        saveCurrentUserMetadataObject({dispatch}, withoutFetch) {
            dispatch("updateUserMetadata", {withoutFetch})
        },
        uploadPhoto({dispatch, commit, state}, {photo, storagePath, mutationType}) {
            const imagesRef = ref(getStorage(), `${storagePath}/${state.firebaseUser.uid}.${photo.name.split('.')[1]}`);
            uploadBytes(imagesRef, photo).then(() => {
                getDownloadURL(imagesRef).then(async downloadUrl => {
                    await commit(mutationType, downloadUrl)
                    dispatch("saveCurrentUserMetadataObject", true)
                }).catch(console.error)
            }).catch(console.error)
        },
        async sendFriendRequest({state}, {them, notification}) {
            if (state) {
                try {
                    const document = doc(getFirestore(), "users", them.uid);
                    await updateDoc(document, {
                        notifications: arrayUnion(notification)
                    });
                    them.notifications.push(notification)
                    toast.success("Friend request sent successfully", {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                } catch (error) {
                    console.error(error);
                    toast.error("An error occurred while sending the friend request", {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                }
            }
        },
        async unSendFriendRequest({state}, {them}) {
            if (state) {
                const me = state.userMetadata;
                try {
                    const document = doc(getFirestore(), "users", them.uid);
                    await updateDoc(document, {
                        notifications: them.notifications.filter(notification => notification.fromUID !== me.uid)
                    });
                    them.notifications = them.notifications.filter(notification => notification.fromUID !== me.uid)
                    toast.success("Friend request un-sent successfully", {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                } catch (error) {
                    toast.error("An error occurred while un-sending the friend request", {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                }
            }
        },
        async acceptFriendRequest({state}, {themUID}) {
            if (state) {
                const me = state.userMetadata.toJSON();
                try {
                    const document = doc(getFirestore(), "users", me.uid);
                    // Add the friend to both users' friends list
                    await updateDoc(document, {
                        friends: arrayUnion(themUID),
                        notifications: me.notifications.filter(notification => notification.fromUID !== themUID)
                    });
                    // Add the current user to the friend's friends list
                    await updateDoc(doc(getFirestore(), "users", themUID), {
                        friends: arrayUnion(me.uid)
                    });
                    me.friends.push(themUID)
                    me.notifications = me.notifications.filter(notification => notification.fromUID !== themUID)
                    toast.success("Friend request accepted!", {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                } catch (error) {
                    console.error(error)
                    toast.error("An error occurred while accepting the friend request", {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                }
            }
        },
        async rejectFriendRequest({state}, {themUID}) {
            if (state) {
                const me = state.userMetadata;
                try {
                    const document = doc(getFirestore(), "users", me.uid);
                    await updateDoc(document, {
                        notifications: me.notifications.filter(notification => notification.fromUID !== themUID)
                    });
                    me.notifications = me.notifications.filter(notification => notification.fromUID !== themUID)
                    toast.success("Friend request rejected", {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                } catch (error) {
                    toast.error("An error occurred while rejecting the friend request", {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                }
            }
        },
        async unFriendUser({state}, {them}) {
            const me = state.userMetadata.toJSON();
            try {
                const document = doc(getFirestore(), "users", me.uid);
                // Remove the friend from both users' friends list
                // remove the friend from the current user's friends list
                await updateDoc(document, {
                    friends: me.friends.filter(friend => friend !== them.uid)
                });
                // remove the current user from the friend's friends list
                await updateDoc(doc(getFirestore(), "users", them.uid), {
                    friends: them.friends.filter(friend => friend !== me.uid)
                });
                me.friends = me.friends.filter(friend => friend !== them.uid)
                them.friends = them.friends.filter(friend => friend !== me.uid)
                state.friendsMetadataObjects = state.friendsMetadataObjects.filter(friend => friend.uid !== them.uid)
                toast.success("Friend removed successfully", {
                    position: 'bottom-right',
                    autoClose: 2000
                });
            } catch (error) {
                toast.error("An error occurred while removing the friend", {
                    position: 'bottom-right',
                    autoClose: 2000
                });
            }
        },
        async changeEmail({state, dispatch}, {newEmail}) {
            try {
                state.userMetadata.email = newEmail;
                await dispatch("updateUserMetadata", {withoutFetch: true});
                toast.success("Email updated successfully", {
                    position: 'bottom-right',
                    autoClose: 2000
                });
            } catch (error) {
                toast.error("An error occurred while updating the email", {
                    position: 'bottom-right',
                    autoClose: 2000
                });
            }
        },
        async sendPasswordResetEmail({commit, state}, {email}) {
            state.isLoading = true;
            try {
                await sendPasswordResetEmail(getAuth(), email);
                commit("setError", "");
                state.isLoading = false;
                toast.success("Password reset email sent successfully", {
                    position: 'bottom-right',
                    autoClose: 2000
                });
            } catch (error) {
                console.error(error);
                commit("setError", authErrors[error.code.substring(error.code.indexOf('/') + 1)]);
                state.isLoading = false;
                toast.error("An error occurred while sending the password reset email", {
                    position: 'bottom-right',
                    autoClose: 2000
                });
            }
        },
        async resetPassword({state, commit}, {code, password}) {
            state.isLoading = true
            confirmPasswordReset(getAuth(), code, password).then(() => {
                commit("setError", "")
                state.isLoading = false
                router.push("/login")
            }).catch((error) => {
                commit("setError",
                    authErrors[error.code.substring(error.code.indexOf('/') + 1)])
                state.isLoading = false
            })
        },
    },
}

export default authenticationModule