<template>
  <div class="WishlistTab__wishlist-container mt-5">
    <div class="grid grid-cols-1 sm:grid-cols-3 auto-rows-fr gap-10">
      <WishlistCard v-for="(wishlist, index) in wishlists"
                    :wishlist="wishlist"
                    :index="index"
                    :username="userMetadataProp.username"
                    :mode="WishlistCardTypes.VIEW"
                    :key="wishlist.id"/>
    </div>
  </div>
</template>

<script>
import WishlistCard from "@/components/Dashboard/WishlistCard";
import {WishlistCardTypes} from "@/Enums/WishlistCardTypes";
import {WishlistPrivacyTypes} from "@/Enums/WishlistPrivacyTypes";
import {computed, onMounted, ref} from "vue";
import {doc, getDoc, getFirestore} from "firebase/firestore";
import {useStore} from "vuex";

export default {
  name: "WishlistsTab",
  components: {
    WishlistCard
  },
  props: {
    userMetadataProp: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const wishlists = ref([])
    const isFriend = computed(() => {
      const me = store.state.authentication.userMetadata
      const them = props.userMetadataProp;
      return me.friends.find(friend => friend === them.uid);
    });


    onMounted(() => {
      getDoc(doc(getFirestore(), "wishlists", props.userMetadataProp.uid))
          .then((docResult) => {
            if (docResult.exists()) {
              if (docResult.data().wishlists) {
                docResult.data().wishlists.forEach(wishlist => {
                  if (wishlist.privacy === WishlistPrivacyTypes.FRIENDS && isFriend.value) {
                    wishlists.value.push(wishlist)
                  } else if (wishlist.privacy === WishlistPrivacyTypes.EVERYONE) {
                    wishlists.value.push(wishlist)
                  }
                })
              }
            }
          })
    })

    return {
      WishlistCardTypes,
      WishlistPrivacyTypes,
      wishlists,
      isFriend
    };
  }
}
</script>

<style scoped lang="scss">

</style>