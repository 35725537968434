<template>
  <div v-if="props.isOpen" class="modal-mask">
    <div :class="`bg-white-marble m-auto modal-wrapper w-[${props.width}]`">
      <div class="modal-container border-primary border-2 rounded-b-md rounded-md" ref="target">
        <div class="modal-header text-primary">
          <slot name="header"> default header </slot>
        </div>
        <div class="modal-body">
          <slot name="content"> default content </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  props: {
    isOpen: Boolean,
    width: {
      type: String,
      default: '80%'
    },
  },
  setup(props, { emit }) {
    const target = ref(null)
    onClickOutside(target, () => emit('close'))

    return {
      props,
      target
    }
  }
})
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-container {
  margin: 150px auto;
  padding: 20px 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-body {
  max-height: 78vh;
  overflow-y: auto;
}

</style>