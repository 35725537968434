<template>
  <div class="flex">
    <img class="inline-block w-16 h-16 rounded-full border-primary border-2" :src="notification.fromPhotoURL ? notification.fromPhotoURL : ProfilePhotoPlaceholder"
         :alt="`Profile photo for ${notification.fromUsername}`"/>
    <div class="inline-block ml-4">
      <router-link :to="`/user/${notification.fromUsername}`" class="text-lg text-primary font-bold">{{ notification.fromName }}</router-link>
      <p class="text-sm">{{ timeAgo }}</p>
      <div class="flex mt-2">
        <button @click="acceptFriendRequest" class="bg-primary text-white rounded-md px-4 py-2 mr-2 text-md hover:brightness-90 active:brightness-75">Accept</button>
        <button @click="rejectFriendRequest" class="bg-gray-500 text-black rounded-md px-4 py-2 ml-2 text-md hover:brightness-90 active:brightness-75">Reject</button>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import ProfilePhotoPlaceholder from "@/assets/profilePhotoPlaceholder.webp";
import {defineComponent, ref} from "vue";
import TimeAgo from 'javascript-time-ago'
import {useStore} from "vuex";

export default defineComponent({
  name: "NotificationFriendRequest",
  props: {
    notificationProp: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const timeAgoObject = new TimeAgo('en-US')
    const timeAgo = timeAgoObject.format(new Date(props.notificationProp.time))
    const notification = ref(props.notificationProp)

    const acceptFriendRequest = () => {
      store.dispatch('authentication/acceptFriendRequest', {themUID: notification.value.fromUID})
    }

    const rejectFriendRequest = () => {
      store.dispatch('authentication/rejectFriendRequest', {themUID: notification.value.fromUID})
    }

    return {
      ProfilePhotoPlaceholder,
      acceptFriendRequest,
      rejectFriendRequest,
      notification,
      timeAgo
    }
  }
})
</script>