<template>
  <div class="flex flex-col items-center justify-center">
    <input type="file" accept="image/*" @change="handleFileChange" class="mb-4 p-2 border-2 border-gray-300" />
    <img v-if="previewUrl" :src="previewUrl" class="mx-auto my-4 h-72" :class="{ 'rounded-full w-72 object-cover': props.isRounded }" alt="preview image"/>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'FileUploader',
  props: {
    isRounded: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const file = ref(null);
    const previewUrl = ref(null);

    const handleFileChange = (event) => {
      file.value = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        previewUrl.value = e.target.result;
        context.emit('file-selected', {file: file.value, previewURL: previewUrl.value});
      };
      reader.readAsDataURL(file.value);

    };

    return {
      file,
      previewUrl,
      handleFileChange,
      props
    };
  }
});
</script>
