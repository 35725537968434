<template>
  <div class="grid grid-cols-4 active:bg-gray-400 hover:bg-gray-300 m-5 p-2 rounded cursor-pointer">
    <router-link class="flex items-center col-span-3" :to="`/user/${friend.username}`">
      <img class="inline rounded-full w-20 h-20 mb-2 bg-marble"
           :src="(friend.photoURL) ? friend.photoURL : ProfilePhotoPlaceholder" :alt="friend.username">
      <div class="ms-10">
        <h1 class="text-2xl font-bold text-primary">{{ friend.username }}</h1>
        <p class="text-lg">{{ friend.firstName }} {{ friend.lastName }}</p>
      </div>
    </router-link>
    <div class="text-right">
      <button
          v-if="!viewing"
          @click="$emit('removeFriend')"
          class="bg-gray-500 m-2 text-white p-2 rounded mt-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
        REMOVE FRIEND
      </button>
    </div>
  </div>
</template>

<script lang="js">
import {computed, defineComponent} from 'vue';
import ProfilePhotoPlaceholder from "@/assets/profilePhotoPlaceholder.webp";

export default defineComponent({
  name: "FriendCard",
  props: {
    friendProp: {
      type: Object,
      required: true
    },
    viewing: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const friend = computed(() => props.friendProp);

    return {
      ProfilePhotoPlaceholder,
      friend
    }
  }
});
</script>