<template>
  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-lg">
      <h2 class="mt-10 pt-5 pb-5 text-center text-2xl font-bold tracking-tight text-white bg-primary">Reset
        Password</h2>
    </div>

    <div class="relative mt-5 sm:mx-auto sm:w-full sm:max-w-lg">
      <LoadingComponent v-if="isLoading"/>

      <Form @submit="onSubmit" class="space-y-6 gap-4">
        <!-- Password -->
        <div>
          <Field name="password" :rules="{ required: true, hasSpecialChar: true, hasNumber: true, min: 8 }"
                 v-slot="{ field, errors }">
            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password:</label>
            <input v-bind="field" id="password" name="password" type="password" ref="password"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>
        </div>

        <!-- Confirm Password -->
        <div>
          <Field name="confirmPassword" :rules="{ required: true, confirmed: 'password'}"
                 v-slot="{ field, errors }">
            <label for="confirmPassword" class="block text-sm font-medium leading-6 text-gray-900">Confirm
              Password:</label>
            <input v-bind="field" id="confirmPassword" name="confirmPassword" type="password"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>
        </div>

        <div class="col-span-2 text-center">
          <div class="text-red-500 text-sm mt-2">{{ error }}</div>
          <button
              class="w-[60%] inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5 active:brightness-90"
              type="submit">RESET PASSWORD
          </button>
          <div>
            If you would like to return to the login page, please
            <router-link to="/login"
                         class="inline-block px-2 py-1 text-xs font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5">
              Login
            </router-link>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent} from 'vue';
import {Form, Field, defineRule, configure} from 'vee-validate';
import {required} from "@vee-validate/rules";
import {camelCaseToSentence, fieldCompare, hasNumber, hasSpecialChar} from "@/plugins/veeValidateHelperMethods";
import {useStore} from "vuex";
import LoadingComponent from "@/components/LoadingComponent.vue";
import {useRoute} from "vue-router";

defineRule('required', required);
defineRule('confirmed', fieldCompare);
defineRule('hasSpecialChar', hasSpecialChar);
defineRule('hasNumber', hasNumber);

configure({
  validateOnInput: true,
  generateMessage: (context) => {
    const fieldName = camelCaseToSentence(context.field);
    const messages = {
      required: `${fieldName} is required`,
      confirmed: `The ${fieldName} does not match`,
      hasSpecialChar: `The ${fieldName} must contain a special character`,
      hasNumber: `The ${fieldName} must contain a number`,
    };
    return messages[context.rule.name] ?? `The ${fieldName} is invalid`;
  },
});

export default defineComponent({
  name: 'ResetPasswordPage',
  components: {LoadingComponent, Form, Field},
  setup() {
    const store = useStore();
    const route = useRoute();
    const error = computed(() => store.state.authentication.error);
    const isLoading = computed(() => store.state.authentication.isLoading)
    const onSubmit = async (values) => {
      await store.dispatch('authentication/resetPassword', {code: route.query.oobCode, password: values.password});
    };

    return {
      onSubmit,
      error,
      isLoading
    };
  },
});
</script>
