<template>
  <div class="relative"
       @mouseover="hoverActive = true"
       @mouseleave="hoverActive = false">
    <ConfirmationModal :is-modal-open="isConfirmModalOpen" @close="isConfirmModalOpen = false" @confirmed="deleteProduct" header="Delete Product" body="Are you sure you want to delete this product?" confirmButtonText="Delete"/>
    <ProductModal :mode="mode" :is-modal-open="isEditModalOpen" @close="isEditModalOpen = false" :product-prop="productRef" :wishlist="wishListRef" @editProduct="$emit('editProduct')" @updatePurchaser="updatePurchaser"/>
    <div v-if="mode === ProductCardTypes.VIEW && !isAvailable" class="flex justify-center items-center absolute inset-0">
      <div>
        <img class="w-full" src="@/assets/goldCross.webp" alt="Gold cross">
      </div>
    </div>
    <div v-show="hoverActive"
         v-if="mode === ProductCardTypes.EDIT"
         class="absolute inset-0 m-auto h-auto top-0 bottom-0 left-0 right-0 text-center z-10 flex flex-col justify-center">
      <button @click="isEditModalOpen = true; hoverActive = false" class="text-white p-2 rounded bg-primary m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
        EDIT ITEM
      </button>
      <button @click="isConfirmModalOpen = true" class="text-white p-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
        DELETE ITEM
      </button>
    </div>
    <div v-show="hoverActive"
         v-else-if="mode === ProductCardTypes.VIEW"
          class="absolute inset-0 m-auto h-auto top-0 bottom-0 left-0 right-0 text-center z-10 flex flex-col justify-center">
      <button @click="isEditModalOpen = true; hoverActive = false" class="text-white p-2 rounded bg-primary m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
        VIEW ITEM
      </button>
    </div>
    <h1 class="text-center text-3xl m-2 font-medium text-primary overflow-hidden text-ellipsis text-nowrap whitespace-nowrap">{{ productRef.name }}</h1>
    <div
        class="bg-marble p-5 rounded-lg shadow-lg overflow-hidden border-primary border-2 h-[35em] py-5 flex items-center justify-center">
      <img class="p-2 w-full object-cover" :src="productRef.image ? productRef.image : coverPhotoPlaceholder" alt="Product image"/>
    </div>
    <h2 class="text-center mt-2 text-xl text-gray-500">${{ productRef.price }}</h2>
  </div>
</template>

<script>
import {computed, defineComponent, ref, watch} from "vue";
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";
import ProductModal from "@/components/Modals/ProductModal.vue";
import coverPhotoPlaceholder from '@/assets/coverPhotoPlaceholder.webp';
import {ProductModalTypes} from "@/Enums/ProductModalTypes";
import {ProductCardTypes} from "@/Enums/ProductCardTypes";

export default defineComponent({
  name: "ProductCard",
  components: {ProductModal, ConfirmationModal},
  props: {
    product: {
      type: Object,
      required: true
    },
    wishlist: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: ProductCardTypes.EDIT
    }
  },
  setup(props, context) {
    const productRef = ref(props.product);
    const wishListRef = ref(props.wishlist);
    const hoverActive = ref(false);
    const isEditModalOpen = ref(false);
    const isConfirmModalOpen = ref(false);
    const isAvailable = computed(() => {
      return props.product.purchaser.length === 0
    });

    watch(isEditModalOpen, () => {
      hoverActive.value = false;
    })

    const deleteProduct = () => {
      context.emit('deleteProduct', {wishlistID: wishListRef.value.id, productID: productRef.value.id});
    }

    const updatePurchaser = (product) => {
      context.emit('updatePurchaser', product);
    }

    return {
      updatePurchaser,
      deleteProduct,
      ProductCardTypes,
      ProductModalTypes,
      coverPhotoPlaceholder,
      isAvailable,
      isConfirmModalOpen,
      isEditModalOpen,
      hoverActive,
      productRef,
      wishListRef
    };
  }
})
</script>
