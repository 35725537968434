export const camelCaseToSentence = (camelCase) => {
    let sentence = camelCase.replace(/([A-Z])/g, ' $1');
    return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
}

export const fieldCompare = (value, [target], ctx) => {
    return value === ctx.form[target] || 'Fields do not match';
}

//eslint-disable-next-line
export const hasSpecialChar = (value) => (value) ? /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value) : false

//eslint-disable-next-line
export const hasNumber = (value) => (value) ? /\d/.test(value) : false

export const noSpaces = (value) => {
    return (value) ? !/\s/.test(value) : false
}