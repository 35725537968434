import {createApp} from 'vue'
import {initializeApp} from "firebase/app";
import {getAuth, connectAuthEmulator} from "firebase/auth";
import {getAnalytics, isSupported} from "firebase/analytics";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import {connectStorageEmulator, getStorage} from "firebase/storage";
import App from './App.vue'
import store from './store/store'
import router from './plugins/router'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faPenSquare,
    faPlus,
    faQuestionCircle,
    faUserPlus,
    faUserCheck,
    faCheck,
    faBell,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faChevronDown,
    faChevronUp
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faPenSquare, faPlus, faQuestionCircle, faUserPlus,
    faUserCheck, faCheck, faBell, faAngleDoubleLeft, faAngleDoubleRight,
    faChevronDown, faChevronUp)

import './main.scss'
import TimeAgo from "javascript-time-ago";
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
isSupported().then(() => {
    getAnalytics(firebaseApp);
})

if (location.hostname === 'localhost') {
    console.log('Emulators Connected')
    connectAuthEmulator(getAuth(), 'http://localhost:9099')
    connectFirestoreEmulator(getFirestore(), 'localhost', 9090)
    connectStorageEmulator(getStorage(), 'localhost', 9199)
}

let app = null

getAuth().onAuthStateChanged((firebaseUser) => {
    store.commit("authentication/setFirebaseUser", firebaseUser)
    if (!app) {
        app = createApp(App)
            .use(router)
            .use(store)
        app.component('font-awesome-icon', FontAwesomeIcon)
        app.mount('#app')
    }
});
