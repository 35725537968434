export const themeTypes = [
    {name: 'shoppingBag', text: 'General'},
    {name: 'christmasTree', text: 'Christmas'},
    {name: 'birthdayCake', text: 'Birthday'},
    {name: 'egg', text: 'Easter'},
    {name: 'love', text: 'Valentines Day'},
    {name: 'dreamingInCloud', text: 'Desires'},
    {name: 'maternity', text: 'Mothers Day'},
    {name: 'baby', text: 'Fathers Day'},
    {name: 'weddingRings', text: 'Wedding'}
];
