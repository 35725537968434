import { createStore } from 'vuex'
import authenticationModule from './modules/authentication'
import wishlistModule from "@/store/modules/wishlist";

export default createStore({
    namespaced: true,
    modules: {
        authentication: authenticationModule,
        wishlist: wishlistModule
    }
})