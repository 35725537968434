<script setup>

</script>

<template>
  <h1>Learn More</h1>
</template>

<style scoped lang="scss">

</style>