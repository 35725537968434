import {arrayUnion, doc, getDoc, getFirestore, setDoc, updateDoc} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import router from "@/plugins/router";
import {toast} from 'vue3-toastify';
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import store from "@/store/store";

const wishlistModule = {
    namespaced: true,
    state: () => ({
        wishlistMetadata: {
            wishlists: []
        },
        isLoading: true
    }),
    mutations: {
        setWishlistMetadata(state, wishlists) {
            state.wishlistMetadata = wishlists
        },
        addWishlist(state, wishlist) {
            // set the wishlist id to the current length of the wishlist array appended with the current user's uid
            wishlist.id = `${getAuth().currentUser.uid}-${state.wishlistMetadata.wishlists.length}`;
            state.wishlistMetadata.wishlists.push(wishlist)
        },
        setIsLoading(state, loading) {
            state.isLoading = loading
        }
    },
    actions: {
        async addProductToWishlist({state, dispatch}, {wishlistID, product}) {
            const user = getAuth().currentUser;
            const wishlist = state.wishlistMetadata.wishlists.find(w => w.id === wishlistID);
            product.id = `${user.uid}-${wishlist.items.length + 1}`;
            if (product.image !== undefined && product.image !== null) {
                product.image = await dispatch("uploadProductImage", product);
            } else {
                delete product.image;
            }
            wishlist.items.push(product);
            await dispatch("updateWishlist", wishlist);
        },
        async uploadProductImage({state}, product) {
            if (state) {
                const imageRef = ref(getStorage(), `itemImages/${product.id}`);
                await uploadBytes(imageRef, product.image);
                return await getDownloadURL(imageRef);
            } else {
                return null;
            }
        },
        async editProductInWishlist({state, dispatch}, {wishlistID, product}) {
            for (const wishlist of state.wishlistMetadata.wishlists) {
                if (wishlist.id === wishlistID) {
                    const index = wishlist.items.findIndex(i => i.id === product.id)
                    if (index !== -1) {
                        if (product.image !== wishlist.items[index].image) {
                            if (product.image !== undefined && product.image !== null) {
                                product.image = await dispatch("uploadProductImage", product);
                            }
                        }
                        wishlist.items[index] = product;
                        dispatch("updateWishlist", wishlist);
                    }
                }
            }
        },
        async deleteProductFromWishlist({state, dispatch}, {wishlistID, productID}) {
            for (const wishlist of state.wishlistMetadata.wishlists) {
                if (wishlist.id === wishlistID) {
                    const index = wishlist.items.findIndex(i => i.id === productID)
                    if (index !== -1 && wishlist.items[index].image) {
                        await store.dispatch("authentication/deletePhoto", {
                            photoURL: wishlist.items[index].image
                        })
                        wishlist.items.splice(index, 1)
                        await dispatch("updateWishlist", wishlist)
                        await dispatch("fetchWishlists")
                    }
                }
            }
        },
        async createWishlist({commit, dispatch}, wishlist) {
            commit("addWishlist", wishlist.toJSON())
            dispatch("updateWishlistDoc").then(() => {
                toast.success("Wishlist created successfully", {
                    position: 'bottom-right',
                    autoClose: 2000
                })
            }).catch(() => {
                toast.error("An error occurred while creating the wishlist", {
                    position: 'bottom-right',
                    autoClose: 2000
                })
            })
        },
        async fetchWishlists({commit, dispatch}) {
            commit("setIsLoading", true)
            const user = getAuth().currentUser
            if (user === null) {
                return
            }
            getDoc(doc(getFirestore(), "wishlists", user.uid))
                .then((docResult) => {
                    const data = docResult.data()
                    if (data !== undefined) {
                        // Wishlist document has been found successfully.
                        commit("setWishlistMetadata", data)
                        commit("setIsLoading", false)
                    } else {
                        // Wishlist document must not exist in "wishlists" collection, we need to add a blank one.
                        dispatch("updateWishlistDoc")
                    }
                }).catch((error) => { console.error(error); commit("setIsLoading", false)})
        },
        async updateWishlistDoc({state, commit, dispatch}) {
            commit("setIsLoading", true)
            const user = getAuth().currentUser
            const document = doc(getFirestore(), "wishlists", user.uid)
            try {
                await updateDoc(document, state.wishlistMetadata)
                // Document updated and can now be set to the metadata object.
                commit("setWishlistMetadata", state.wishlistMetadata)
                commit("setIsLoading", false)
            } catch (error) {
                console.error(error)
                try {
                    // The document does not exist, so we need to add one.
                    await setDoc(document, {
                        wishlists: []
                    })
                    commit("setIsLoading", false)
                    await dispatch("fetchWishlists")
                } catch (error) {
                    console.error(error)
                    console.error("Wishlist details not added")
                    commit("setIsLoading", false)
                }
            }
        },
        async deleteWishlist({state, dispatch}, wishlistID) {
            const index = state.wishlistMetadata.wishlists.findIndex(w => w.id === wishlistID)
            // Delete wishlist items images if they exist
            if (state.wishlistMetadata.wishlists[index].items) {
                for (const product of state.wishlistMetadata.wishlists[index].items) {
                    if (product.image !== undefined && product.image !== null) {
                        await store.dispatch('authentication/deletePhoto', {
                            photoURL: product.image
                        })
                    }
                }
            }
            state.wishlistMetadata.wishlists.splice(index, 1)
            await dispatch("updateWishlistDoc")
            await router.push({name: "Dashboard"})
        },
        async deleteWishlists({state, dispatch}, {wishlists}) {
            if (state) {
                // Delete all wishlists
                for (const wishlist of wishlists) {
                    await dispatch("deleteWishlist", wishlist.id)
                }
            }
        },
        async updateWishlist({state, dispatch}, wishlist) {
            const index = state.wishlistMetadata.wishlists.findIndex(w => w.id === wishlist.id)
            state.wishlistMetadata.wishlists[index] = wishlist
            await dispatch("updateWishlistDoc").then(() => {
                toast.success("Wishlist updated successfully", {
                    position: 'bottom-right',
                    autoClose: 2000
                })
            }).catch(() => {
                toast.error("An error occurred while updating the wishlist", {
                    position: 'bottom-right',
                    autoClose: 2000
                })
            })
        },
        async addPurchaserToProduct({state}, {themUID, wishlistID, productID}) {
            try {
                if (!state) return

                const { currentUser: user } = getAuth();
                const theirDoc = doc(getFirestore(), "wishlists", themUID);
                const docSnap = await getDoc(theirDoc);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const wishlist = data.wishlists.find(wishlist => wishlist.id === wishlistID);
                    const item = wishlist.items.find(item => item.id === productID);
                    item.purchaser.push(user.uid);
                    await updateDoc(doc(getFirestore(), "users", user.uid), {
                        shoppingList: arrayUnion(item)
                    });
                    await updateDoc(theirDoc, data);
                    toast.success("You have added this item to your shopping list.", {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                }
            } catch (error) {
                console.error(error);
                toast.error("An error occurred while adding this item to your shopping list.", {
                    position: 'bottom-right',
                    autoClose: 2000
                });
            }
        },
        async removePurchaserFromProduct({state}, {them, wishlistID, productID, myShoppingList}) {
            try {
                if (!state) return

                const { currentUser: user } = getAuth();
                const firestore = getFirestore();
                const docRef = doc(firestore, "wishlists", them.owner);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const wishlist = data.wishlists.find(wishlist => wishlist.id === wishlistID);
                    const item = wishlist.items.find(item => item.id === productID);
                    const index = item.purchaser.findIndex(purchaser => purchaser === user.uid);
                    item.purchaser.splice(index, 1);
                    await updateDoc(doc(getFirestore(), "users", user.uid), {
                        shoppingList: myShoppingList.filter(item => item.id !== productID)
                    });
                    await updateDoc(docRef, data);
                    toast.success("You have removed this item from your shopping list.", {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                }
            } catch (error) {
                console.error(error);
                toast.error("An error occurred while removing this item from your shopping list.", {
                    position: 'bottom-right',
                    autoClose: 2000
                });
            }
        }
    }
}

export default wishlistModule