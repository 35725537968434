<template>
  <div class="relative flex justify-center items-center p-5">
    <Form :initial-values="initialValues" @submit="onSubmit" @reset="onReset" class="w-full">

      <!-- Price -->
      <div class="mb-4">
        <Field name="min" v-slot="{ errors }">
          <label class="block text-lg font-bold mb-1" for="min">Min</label>
          <input v-model="initialValues.min" id="min" name="min" type="number"
                 class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-sm mt-2">{{ error }}</div>
          </div>
        </Field>
        <Field name="max" v-slot="{ errors }">
          <label class="block text-lg font-bold mb-1" for="max">Max</label>
          <input v-model="initialValues.max" id="max" name="max" type="number"
                 class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-sm mt-2">{{ error }}</div>
          </div>
        </Field>
      </div>

      <!-- Availability -->
      <div class="mb-4">
        <Field name="available">
          <div class="mb-4">
            <div class="flex items-center">
              <input id="available" name="availability" type="radio" value="available"
                     class="form-radio h-6 w-6 accent-primary text-primary checked:bg-green-500" v-model="initialValues.available">
              <label for="available" class="text-xl ml-2">Available</label>
            </div>
            <div class="flex items-center">
              <input id="purchased" name="availability" type="radio" value="purchased"
                     class="form-radio h-6 w-6 accent-primary text-primary checked:bg-green-500" v-model="initialValues.available">
              <label for="purchased" class="text-xl ml-2">Purchased</label>
            </div>
            <div class="flex items-center">
              <input id="all" name="availability" type="radio" value="all"
                     class="form-radio h-6 w-6 accent-primary checked:bg-primary checked:text-primary" v-model="initialValues.available">
              <label for="all" class="text-xl ml-2">All</label>
            </div>
          </div>
        </Field>
      </div>

      <div>
        <button
            class="w-[100%] block px-2 py-1 text-md font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5"
            type="submit">
          APPLY FILTERS
        </button>
        <button
            class="w-[100%] block px-2 py-1 text-md font-medium leading-6 text-center text-white uppercase transition bg-gray-500 rounded shadow ripple hover:shadow-lg hover:bg-gray-600 focus:outline-none mt-5"
            type="reset">
          RESET
        </button>
      </div>
    </Form>
  </div>
</template>

<script>
import {Form, Field, configure} from 'vee-validate';
import {reactive} from "vue";
import _ from "lodash";

configure({
  validateOnInput: true
});

export default {
  name: 'EditWishlistDetailForm',
  components: {Form, Field},
  setup(props, context) {
    const initialValues = reactive({
      min: null,
      max: null,
      available: 'all',
    });
    const onSubmit = () => {
      context.emit('updateFilters', _.cloneDeep(initialValues));
    };

    const onReset = () => {
      initialValues.min = null;
      initialValues.max = null;
      initialValues.available = 'all';
      context.emit('updateFilters', { min: null, max: null, available: 'all' });
    };

    return {
      onSubmit,
      onReset,
      initialValues
    }
  }
}
</script>