<template>
  <div class="relative">
    <EditEmailModal :is-modal-open="isChangeEmailModalOpen" @close="isChangeEmailModalOpen = false"/>
    <EditPasswordModal :is-modal-open="isChangePasswordModalOpen" @close="isChangePasswordModalOpen = false"/>
    <DeleteAccountModal :is-modal-open="isDeleteAccountModalOpen" @close="isDeleteAccountModalOpen = false"/>
    <LoadingComponent v-if="isLoading"/>
    <h1 class="text-4xl font-bold">Privacy</h1>
    <div v-if="currentValues">
      <form @submit.prevent="onSubmit" class="space-y-6 my-5">
        <div class="flex items-center">
          <input v-model="currentValues.isPublic" id="isAccountPublic" name="isAccountPublic" type="checkbox" class="h-8 w-8 text-white accent-primary" />
          <label for="isAccountPublic" class="ml-2 text-lg font-medium leading-6 text-gray-900">Is Account public</label>
        </div>
        <div class="flex items-center">
          <input v-model="currentValues.showRealName" id="showRealName" name="showRealName" type="checkbox" class="form-checkbox h-8 w-8 accent-primary" />
          <label for="showRealName" class="ml-2 text-lg font-medium leading-6 text-gray-900">Show real name on account</label>
        </div>
        <div class="flex items-center">
          <input v-model="currentValues.showBirthday" id="showBirthday" name="showBirthday" type="checkbox" class="form-checkbox h-8 w-8 accent-primary" />
          <label for="showBirthday" class="ml-2 text-lg font-medium leading-6 text-gray-900">Show birthday on account</label>
        </div>
        <div class="flex items-center">
          <input v-model="currentValues.isFriendsListPublic" id="makeFriendsListPublic" name="makeFriendsListPublic" type="checkbox" class="form-checkbox h-8 w-8 accent-primary" />
          <label for="makeFriendsListPublic" class="ml-2 text-lg font-medium leading-6 text-gray-900">Make friends list public</label>
        </div>

        <button class="block px-4 py-2 bg-gray-400 text-white rounded mr-2" v-if="isSignedInWithFirebase" type="button" @click="isChangeEmailModalOpen = true">
          <font-awesome-icon icon="circle-question"/> CHANGE EMAIL</button>
        <button class="block px-4 py-2 bg-gray-400 text-white rounded mr-2" v-if="isSignedInWithFirebase" type="button" @click="isChangePasswordModalOpen = true">
          <font-awesome-icon icon="circle-question"/> CHANGE PASSWORD</button>
        <button class="block px-4 py-2 bg-gray-400 text-white rounded mr-2" type="button" @click="isDeleteAccountModalOpen = true">
          <font-awesome-icon icon="circle-question"/> DELETE ACCOUNT</button>
<!--        <button class="block px-4 py-2 bg-gray-400 text-white rounded mr-2" type="button">
          <font-awesome-icon icon="circle-question"/> BLOCKED USERS</button>-->

        <div class="input-errors" v-if="error">
          <div class="text-red-500 text-md mt-2">{{ error }}</div>
        </div>

        <div class="my-5">
          <button type="reset"
                  class="bg-gray-500 mr-1 text-white p-2 rounded mt-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
            RESET
          </button>

          <button type="submit"
                  class="bg-primary ml-1 text-white p-2 rounded mt-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
            SAVE CHANGES
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { toast } from 'vue3-toastify';
import {computed, defineComponent, ref, watch} from 'vue';
import _ from "lodash";
import store from "@/store/store";
import {mapState} from "vuex";
import LoadingComponent from "@/components/LoadingComponent.vue";
import EditEmailModal from "@/components/Modals/EditEmailModal.vue";
import EditPasswordModal from "@/components/Modals/EditPasswordModal.vue";
import DeleteAccountModal from "@/components/Modals/DeleteAccountModal.vue";
import {getAuth} from "firebase/auth";

export default defineComponent({
  name: "PrivacyTab",
  components: {LoadingComponent, EditEmailModal, EditPasswordModal, DeleteAccountModal},
  computed: {
    ...mapState('authentication', ['error', 'isLoading'])
  },
  setup: function () {
    const initialValues = ref(null);
    const currentValues = ref(null);
    const isChangeEmailModalOpen = ref(false);
    const isChangePasswordModalOpen = ref(false);
    const isDeleteAccountModalOpen = ref(false);

    watch(() => store.state.authentication.userMetadata, (newVal) => {
      if (_.isEmpty(newVal)) return;
      const userData = newVal.toJSON();
      initialValues.value = userData
      currentValues.value = _.cloneDeep(userData);
    }, {immediate: true});

    const onSubmit = () => {
      if (!_.isEqual(initialValues.value, currentValues.value)) {
        store.dispatch('authentication/firebaseUpdateProfile', { user: currentValues.value });
        toast.success('Profile updated successfully', {
          position: 'bottom-right',
          autoClose: 2000
        });
      } else {
        toast.error('No changes detected', {
          position: 'bottom-right',
          autoClose: 2000
        });
      }
    }

    const isSignedInWithFirebase = computed(() =>{
      if (!getAuth().currentUser) {
        return false
      }
      return getAuth().currentUser.providerData[0].providerId === "password"
    })

    return {
      onSubmit,
      isDeleteAccountModalOpen,
      isChangePasswordModalOpen,
      isChangeEmailModalOpen,
      isSignedInWithFirebase,
      initialValues,
      currentValues
    }
  }
})
</script>
