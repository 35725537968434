<template>
  <div v-if="isLoggedIn">
    <h1>Dashboard</h1>
  </div>
  <div v-else>
    <LandingPage />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import LandingPage from "@/views/LandingPage.vue";

export default defineComponent({
  name: 'HomePage',
  components: {
    LandingPage,
  },
  setup() {
    return {
      isLoggedIn: false,
    }
  }
})
</script>

<style scoped lang="scss">

</style>