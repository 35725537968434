import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import LearnMorePage from '@/views/LearnMorePage.vue'
import LoginPage from '@/views/LoginPage.vue'
import RegisterPage from '@/views/RegisterPage.vue'
import ForgotPasswordPage from '@/views/ForgotPasswordPage.vue'
import PasswordResetPage from '@/views/PasswordResetPage.vue'
import DashboardPage from '@/views/Dashboard/DashboardPage.vue'
import EditWishlistPage from '@/views/EditWishlist/EditWishlistPage.vue'
import EditDashboardPage from "@/views/EditAccount/EditDashboardPage.vue";
import ViewUser from "@/views/ViewUser/ViewUserPage.vue";
import store from "@/store/store";
import ViewWishlistPage from "@/views/ViewWishlist/ViewWishlistPage.vue";

const routes = [
    { name: 'Dashboard', path: '/', component: DashboardPage, meta: { requiresAuth: true } },
    { name: 'EditAccount', path: '/account', component: EditDashboardPage, meta: { requiresAuth: true } },
    { name: 'EditWishlist', path: '/wishlist/:id', component: EditWishlistPage, meta: { requiresAuth: true } },
    { name: 'ViewWishlist', path: '/wishlist/:username/:id', component: ViewWishlistPage, meta: { requiresAuth: true } },
    { name: 'ViewUser', path: '/user/:username', component: ViewUser, meta: { requiresAuth: true } },
    { name: 'LandingPage', path: '/home', component: HomePage },
    { name: 'LearnMore', path: '/learnmore', component: LearnMorePage },
    { name: 'Login', path: '/login', component: LoginPage },
    { name: 'Register', path: '/register', component: RegisterPage },
    { name: 'ForgotPassword', path: '/forgot-password', component: ForgotPasswordPage },
    { name: 'ResetPassword', path: '/reset-password', component: PasswordResetPage },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
})

router.beforeEach((to, from, next) => {
    // Check if the route requires authentication and the user is not authenticated
    // and the route is not the LandingPage
    if (to.meta.requiresAuth && !store.state.authentication.firebaseUser && to.name !== 'LandingPage') {
        // If the user is trying to access any other page than the root, redirect them to the LandingPage
        // and save the page they were trying to access in the query parameters
        if (to.fullPath !== '/') {
            return next({
                name: 'LandingPage', query: {
                    redirectUrl: to.fullPath
                }
            })
        } else {
            // If the user is trying to access the root page, just redirect them to the LandingPage
            return next({name: 'LandingPage'})
        }

    } else {
        // If the user is trying to access the login or register page while they are logged in,
        // redirect them to the Dashboard
        if ((to.name === "login" || to.name === "register") && store.state.auth.isLoggedIn) {
            return next({name: 'Dashboard'})
        } else if (from.query.redirectUrl !== undefined && to.query.redirectUrl === undefined) {
            // If the user was redirected from a page that they were trying to access,
            // save that page in the query parameters of the next route
            to.query.redirectUrl = from.query.redirectUrl
            return next(to)
        }

        // If none of the above conditions are met, allow the navigation to proceed
        return next()
    }
})

export default router