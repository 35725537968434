<template>
  <Form class="gap-4 space-y-12" @submit="onSubmit">
    <ModalComponent class="w-36" :is-open="isModalOpen" @close="$emit('close')">
      <template v-slot:header>
        <h1 class="w-full text-center text-5xl">Change Password</h1>
      </template>
      <template v-slot:content>
        <!-- Old Password -->
        <Field name="oldPassword" :rules="{ required: true }" v-slot="{ field, errors }">
          <div class="my-5 flex flex-row items-center">
            <label for="oldPassword" class="block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[180px]">
              <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Enter your current password here"/>
              Old Password:</label>
            <input v-bind="field" id="oldPassword" name="oldPassword" type="password"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6">
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-md mt-2">{{ error }}</div>
          </div>
        </Field>

        <!-- New Password -->
        <Field name="newPassword" :rules="{ required: true, hasSpecialChar: true, hasNumber: true, min: 8 }"
               v-slot="{ field, errors }">
          <div class="my-5 flex flex-row items-center">
            <label for="newPassword"
                   class=" whitespace-nowrap block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[180px]">
              <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Enter your new password here"/>
              New Password:</label>
            <input v-bind="field" id="newPassword" name="newPassword" type="password"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6">
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-md mt-2">{{ error }}</div>
          </div>
        </Field>

        <!-- Confirm New Password -->
        <Field name="confirmNewPassword" :rules="{ required: true, confirmed: 'newPassword' }"
               v-slot="{ field, errors }">
          <div class="my-5 flex flex-row items-center">
            <label for="confirmNewPassword"
                   class="block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[180px]">
              <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Confirm your new password here"/>
              Confirm Password:</label>
            <input v-bind="field" id="confirmNewPassword" name="confirmNewPassword" type="password"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6">
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-md mt-2">{{ error }}</div>
          </div>
        </Field>

      </template>
      <template v-slot:footer>
        <div class="text-red-500 text-sm mt-2">{{ globalError }}</div>
        <div class="text-end">
          <button type="submit"
                  class="text-white px-8 py-2 rounded bg-primary m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
            Update Password
          </button>
          <button @click="$emit('close')" type="reset"
                  class="text-white px-8 py-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
            close
          </button>
        </div>
      </template>
    </ModalComponent>
  </Form>
</template>

<script>
import {defineComponent, ref} from 'vue';
import ModalComponent from "@/components/Modals/ModalComponent.vue";
import {configure, defineRule, Field, Form} from "vee-validate";
import {camelCaseToSentence, fieldCompare, hasNumber, hasSpecialChar} from "@/plugins/veeValidateHelperMethods";
import {required} from "@vee-validate/rules";
import {EmailAuthProvider, getAuth, updatePassword, reauthenticateWithCredential} from "firebase/auth";
import {toast} from "vue3-toastify";
import {authErrors} from "@/plugins/errorMessageHelper";

defineRule('required', required);
defineRule('confirmed', fieldCompare);
defineRule('hasSpecialChar', hasSpecialChar);
defineRule('hasNumber', hasNumber);

configure({
  validateOnInput: true,
  generateMessage: (context) => {
    const fieldName = camelCaseToSentence(context.field);
    const messages = {
      required: `${fieldName} is required`,
      confirmed: `${fieldName} does not match`,
      hasSpecialChar: `${fieldName} must contain a special character`,
      hasNumber: `${fieldName} must contain a number`,
    };
    return messages[context.rule.name] ?? `The ${fieldName} is invalid`;
  },
});

export default defineComponent({
  name: "ConfirmationModal",
  components: {Field, Form, ModalComponent},
  props: {
    isModalOpen: Boolean,
  },
  setup(props, context) {
    const globalError = ref("");
    const onSubmit = (values) => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user && user.email) {
        const credential = EmailAuthProvider.credential(user.email, values.oldPassword);
        // Re-authenticate the user to verify the old password
        reauthenticateWithCredential(user, credential)
            .then(() => {
              // Update the password
              updatePassword(user, values.newPassword)
                  .then(() => {
                    globalError.value = "";
                    context.emit('close');
                    toast.success('Password updated!', {
                      position: 'bottom-right',
                      autoClose: 2000
                    })
                  })
                  .catch((error) => {
                    globalError.value = authErrors[error.code.substring(error.code.indexOf('/') + 1)];
                  });
            })
            .catch((error) => {
              globalError.value = authErrors[error.code.substring(error.code.indexOf('/') + 1)];
              globalError.value += "\n please verify your old password and try again.";
            });
      }
    }

    return {
      onSubmit,
      globalError
    }
  }
});
</script>