<template>
  <div class="w-full md:w-1/4 md:min-h-fit lg:min-h-screen">
    <EditWishlistDetailForm :wishlist-prop="props.wishlistProp" @openAddItemModal="$emit('openAddItemModal')" />
  </div>
</template>

<script>
import EditWishlistDetailForm from "@/views/EditWishlist/EditWishlistDetailForm.vue";

export default {
  name: "WishlistSidebar",
  components: {EditWishlistDetailForm},
  props: {
    wishlistProp: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    return {
      props
    };
  },
}
</script>
