<template>
  <div>
    <ModalComponent class="z-30" width="80%" :is-open="props.isModalOpen">
      <template #header>
        <h1 class="w-full text-center text-3xl">{{ props.mode }} Product</h1>
      </template>
      <template #content>
        <div class="flex flex-col md:flex-row">
          <div class="w-full md:w-2/6 p-4 inline">
            <div
                class="bg-marble p-5 rounded-lg shadow-lg overflow-hidden border-primary border-2 h-[25em] py-5 flex items-center justify-center">
              <img class="p-2 w-full object-cover" :src="(photo) ? photo : productPlaceholderImage"
                   alt="Product image"/>
            </div>
            <div v-if="mode !== ProductModalTypes.VIEW">
              <button @click="isUploadProductModalOpen = true"
                      class="w-[100%] block px-2 py-1 text-md leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:cursor-pointer hover:brightness-90 active:brightness-50 mt-5">
                UPLOAD IMAGE
              </button>
              <button v-if="photo" @click="photo = null; photoFile = null"
                      class="w-[100%] block px-2 py-1 text-md leading-6 text-center text-white uppercase transition bg-gray-400 rounded shadow ripple hover:shadow-lg hover:cursor-pointer hover:brightness-90 active:brightness-50 mt-5">
                DELETE IMAGE
              </button>
            </div>
          </div>
          <div class="w-full md:w-4/6 p-4 inline">
            <Form v-if="mode !== ProductModalTypes.VIEW" :initial-values="initialValues" @submit="handleSubmit"
                  class="space-y-3">
              <!-- Product Name -->
              <Field name="name" :rules="{ required: true, max: constants.PRODUCT_NAME_MAX_LENGTH }"
                     v-slot="{ field, errors }">
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900">
                  <font-awesome-icon class="text-lg mr-2" icon="question-circle"
                                     title="Enter the name of the product here"/>
                  Name:
                </label>
                <input v-bind="field" id="name" name="name" type="text" placeholder="Product name"
                       class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <div class="input-errors m-0" v-for="error in errors" :key="error">
                  <div class="text-red-500 text-sm">{{ error }}</div>
                </div>
              </Field>

              <!-- Product Where to buy -->
              <Field name="whereToBuy" :rules="{ required: true, max: constants.WHERE_TO_FIND_MAX_LENGTH }"
                     v-slot="{ field, errors }">
                <label for="whereToBuy" class="block text-sm font-medium leading-6 text-gray-900">
                  <font-awesome-icon class="text-lg mr-2" icon="question-circle"
                                     title="Enter a website or store name of where people can purchase the product"/>
                  Where to buy:
                </label>
                <input v-bind="field" id="whereToBuy" name="whereToBuy" type="text" placeholder="Website or store name"
                       class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <div class="input-errors m-0" v-for="error in errors" :key="error">
                  <div class="text-red-500 text-sm">{{ error }}</div>
                </div>
              </Field>

              <!-- Product Price -->
              <Field name="price" :rules="{ required: true, min: 0 }" v-slot="{ field, errors }">
                <label for="price" class="block text-sm font-medium leading-6 text-gray-900">
                  <font-awesome-icon class="text-lg mr-2" icon="question-circle"
                                     title="Enter a rough price for this product"/>
                  Price:
                </label>
                <input v-bind="field" id="price" name="price" type="number" placeholder="0.00"
                       class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <div class="input-errors m-0" v-for="error in errors" :key="error">
                  <div class="text-red-500 text-sm">{{ error }}</div>
                </div>
              </Field>

              <!-- Product Description -->
              <Field name="description" :rules="{ max: constants.PRODUCT_DESCRIPTION_MAX_LENGTH }"
                     v-slot="{ field, errors }">
                <label for="description" class="block text-sm font-medium leading-6 text-gray-900">
                  <font-awesome-icon class="text-lg mr-2" icon="question-circle"
                                     title="Add any additional details like size or color here"/>
                  Description:
                </label>
                <textarea v-bind="field" id="description" name="description"
                          placeholder="Size, Color, etc."
                          class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                <div class="input-errors m-0" v-for="error in errors" :key="error">
                  <div class="text-red-500 text-sm">{{ error }}</div>
                </div>
              </Field>
              <div>
                <p v-if="mode === ProductModalTypes.EDIT" class="italic">
                  Remember that there is a possibility someone has added this item to their shopping list or may have
                  already purchased it.
                  So be mindful before making any changes.
                </p>
              </div>
              <!-- submit and close buttons -->
              <div class="flex justify-end">
                <button v-if="mode === ProductModalTypes.ADD"
                        class="text-white p-2 rounded bg-primary m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
                  ADD PRODUCT
                </button>
                <button v-else-if="mode === ProductModalTypes.EDIT"
                        class="text-white p-2 rounded bg-primary m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
                  EDIT PRODUCT
                </button>
                <button @click="$emit('close')" type="reset"
                        class="text-white p-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
                  CANCEL
                </button>
              </div>
            </Form>
            <div v-else>
              <div class="flex justify-between items-center my-5">
                <label for="name" class="text-xl inline-block font-medium leading-6 text-gray-900">What is it:</label>
                <input v-model="initialValues.name" disabled id="name" name="name" type="text"
                       class="p-1 text-xl block w-full sm:w-3/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
              <div class="flex justify-between items-center my-5">
                <label for="whereToBuy" class="text-xl inline-block font-medium leading-6 text-gray-900">Where to
                  buy:</label>
                <input v-model="initialValues.whereToBuy" disabled id="whereToBuy" name="description" type="text"
                       class="p-1 text-xl block w-full sm:w-3/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
              <div class="flex justify-between items-center my-5">
                <label for="price" class="text-xl inline-block font-medium leading-6 text-gray-900">How much
                  money:</label>
                <input v-model="initialValues.price" disabled id="price" name="price" type="text"
                       class="p-1 text-xl block w-full sm:w-3/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
              <div class="flex justify-between items-center my-5">
                <label for="description" class="text-xl inline-block font-medium leading-6 text-gray-900">Additional
                  info:</label>
                <textarea v-model="initialValues.description" disabled id="description" name="description" type="text"
                          class="p-1 text-xl block w-full sm:w-3/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
              </div>
              <div>
                <label for="state" class="text-xl inline-block font-medium leading-6 text-gray-900">Are you getting
                  it:</label>
                <button v-if="itemState === ItemStateTypes.AVAILABLE" @click="addToShoppingList" id="state"
                        class="px-10 text-white p-2 rounded bg-primary m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
                  ADD TO MY SHOPPING LIST
                </button>
                <button v-else-if="itemState === ItemStateTypes.CLAIMED_ME" @click="removeFromShoppingList" id="state"
                        class="px-10 text-white p-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
                  MARK AS AVAILABLE
                </button>
                <button v-else-if="itemState === ItemStateTypes.CLAIMED_OTHER" id="state" disabled
                        class="px-10 text-white p-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
                  SOMEONE HAS PURCHASED THIS ITEM
                </button>
              </div>
              <div class="flex justify-end">
                <button @click="$emit('close')" type="reset"
                        class="text-white p-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer></template>
    </ModalComponent>
    <UploadProductImageModal :is-modal-open="isUploadProductModalOpen" @fileSelected="imageAdded"
                             @close="isUploadProductModalOpen = false"/>
  </div>
</template>

<script>
import {computed, defineComponent, ref} from 'vue'
import ModalComponent from "@/components/Modals/ModalComponent.vue";
import productPlaceholderImage from '@/assets/coverPhotoPlaceholder.webp';
import {ProductModalTypes} from "@/Enums/ProductModalTypes";
import * as constants from "@/constants";

import {Field, Form, defineRule, configure} from 'vee-validate';
import {required, max, min} from '@vee-validate/rules';
import UploadProductImageModal from "@/components/Modals/UploadProductImageModal.vue";
import _ from "lodash";
import {ItemStateTypes} from "@/Enums/ItemStateTypes";
import {useStore} from "vuex";

// Define validation rules
defineRule('required', required);
defineRule('max', max);
defineRule('min', min);

configure({
  validateOnInput: true,
  generateMessage: (context) => {
    const messages = {
      required: `The field ${context.field} is required`,
      min: `The field ${context.field} must be greater than`,
      max: `The field ${context.field} must be less than `,
    };

    return messages[context.rule.name] ?? `The field ${context.field} is invalid`;
  },
});

export default defineComponent({
  name: "ProductModal",
  components: {UploadProductImageModal, ModalComponent, Field, Form},
  props: {
    isModalOpen: Boolean,
    productProp: {
      type: Object,
      required: false
    },
    wishlist: {
      type: Object,
      required: false
    },
    mode: {
      type: String,
      default: ProductModalTypes.EDIT
    }
  },
  setup(props, context) {
    const store = useStore();
    let initialValues = ref({
      id: (props.productProp) ? props.productProp.id : null,
      name: (props.productProp) ? props.productProp.name : '',
      whereToBuy: (props.productProp) ? props.productProp.whereToBuy : '',
      price: (props.productProp) ? props.productProp.price : null,
      description: (props.productProp) ? props.productProp.description : '',
      image: (props.productProp) ? props.productProp.image : '',
      purchaser: (props.productProp) ? props.productProp.purchaser : [],
    })
    let isUploadProductModalOpen = ref(false)
    let photo = ref(props.productProp?.image ?? null);
    let photoFile = ref(null);
    let itemState = computed(() => {
      const me = store.state.authentication.userMetadata
      if (initialValues.value.purchaser.includes(me.uid)) {
        return ItemStateTypes.CLAIMED_ME;
      } else if (initialValues.value.purchaser.length > 0) {
        return ItemStateTypes.CLAIMED_OTHER;
      }
      return ItemStateTypes.AVAILABLE;
    })

    const handleSubmit = async (values) => {
      values.image = photoFile.value;
      if (props.mode === ProductModalTypes.ADD) {
        store.dispatch('wishlist/addProductToWishlist', {
          wishlistID: props.wishlist.id,
          product: values
        })
      } else if (props.mode === ProductModalTypes.EDIT) {
        await store.dispatch('wishlist/editProductInWishlist', {
          wishlistID: props.wishlist.id,
          product: values
        })
        let copy = _.cloneDeep(values)
        copy.image = photo.value
        context.emit('editProduct', copy)
      }
      photo.value = null;
      context.emit('close')
    }

    const imageAdded = ({file, previewURL}) => {
      isUploadProductModalOpen.value = false;
      photo.value = previewURL;
      photoFile.value = file;
    }

    const addToShoppingList = () => {
      initialValues.value.purchaser.push(store.state.authentication.userMetadata.uid);
      store.dispatch('wishlist/addPurchaserToProduct', {
        themUID: props.wishlist.owner,
        wishlistID: props.wishlist.id,
        productID: initialValues.value.id,
      })
      context.emit('updatePurchaser', initialValues.value)
      context.emit('close')
    }

    const removeFromShoppingList = () => {
      const me = store.state.authentication.userMetadata
      initialValues.value.purchaser = initialValues.value.purchaser.filter(purchaser => purchaser !== store.state.authentication.userMetadata.uid);
      console.log(me.shoppingList)
      console.log(initialValues.value.id)
      store.dispatch('wishlist/removePurchaserFromProduct', {
        them: props.wishlist,
        wishlistID: props.wishlist.id,
        productID: initialValues.value.id,
        myShoppingList: me.shoppingList
      })
      context.emit('updatePurchaser', initialValues.value)
      context.emit('close')
    }

    return {
      addToShoppingList,
      removeFromShoppingList,
      imageAdded,
      handleSubmit,
      ProductModalTypes,
      ItemStateTypes,
      photoFile,
      itemState,
      photo,
      isUploadProductModalOpen,
      constants,
      productPlaceholderImage,
      props,
      initialValues
    }
  },
})
</script>
