<template>
  <div class="Dashboard__container">
    <!-- Modals -->
    <UploadProfilePictureModal :is-modal-open="isOpenUploadProfilePictureModal" @close="isOpenUploadProfilePictureModal = false"/>
    <UploadCoverPictureModal :is-modal-open="isOpenUploadCoverPictureModal" @close="isOpenUploadCoverPictureModal = false" />
    <div class="w-full">
      <h2 class="mb-5 w-full pt-5 pb-5 text-center text-2xl font-bold tracking-tight text-white bg-primary">Edit Your Account</h2>
    </div>
    <DashboardProfileHeader :user-metadata-prop="userMetadata"
                            @openUploadProfilePictureModal="isOpenUploadProfilePictureModal = true"
                            @openUploadCoverPictureModal="isOpenUploadCoverPictureModal = true"
                            :hide-profile-data="true" />

    <div class="flex w-full mt-20">
      <div class="w-[30%] text-white text-2xl bg-gray-200 p-2 bg-marble border-primary border-2 rounded pb-64">
        <h1 class="p-2 text-3xl text-primary font-bold">Settings</h1>
        <div v-for="(tab, index) in tabs" :key="index" class="hover:bg-primary hover:text-black hover:brightness-90 cursor-pointer p-2 m-2 rounded active:brightness-75" :class="{ 'bg-primary': selectedTab === tab.name }" @click="selectTab(tab.name)">
          {{ tab.label }}
        </div>
      </div>
      <div class="flex-grow px-10">
        <div v-if="selectedTab === 'general'">
          <GeneralTab />
        </div>
        <div v-else-if="selectedTab === 'privacy'">
          <PrivacyTab />
        </div>
        <div v-else-if="selectedTab === 'faq'">
          <h1 class="text-2xl font-bold">FAQ</h1>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="js">
import {computed, defineComponent, ref} from "vue";
import {useRoute, useRouter} from 'vue-router';
import DashboardProfileHeader from "@/components/Dashboard/DashboardProfileHeader.vue";
import UploadProfilePictureModal from "@/components/Modals/UploadProfilePictureModal.vue";
import UploadCoverPictureModal from "@/components/Modals/UploadCoverPictureModal.vue";
import GeneralTab from "@/views/EditAccount/GeneralTab.vue";
import PrivacyTab from "@/views/EditAccount/PrivacyTab.vue";
import store from "@/store/store";

export default defineComponent({
  name: "EditDashboardPage",
  components: {
    UploadCoverPictureModal,
    UploadProfilePictureModal,
    DashboardProfileHeader,
    GeneralTab,
    PrivacyTab
  },
  setup() {
    const userMetadata = computed(() => store.state.authentication.userMetadata);
    const route = useRoute();
    const router = useRouter();
    const isOpenUploadProfilePictureModal = ref(false);
    const isOpenUploadCoverPictureModal = ref(false);
    const selectedTab = ref(route.query.tab || 'general');
    const tabs = [
      { name: 'general', label: 'General' },
      { name: 'privacy', label: 'Privacy' },
      { name: 'faq', label: 'FAQ' }
    ];

    const selectTab = (tabName) => {
      selectedTab.value = tabName;
      router.push({ query: { ...route.query, tab: tabName } });
    };

    return {
      selectTab,
      userMetadata,
      selectedTab,
      tabs,
      isOpenUploadProfilePictureModal,
      isOpenUploadCoverPictureModal
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/styles/dashboard";
</style>