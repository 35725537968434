<template>
  <div class="WishlistTab__wishlist-container mt-5 flex flex-col md:grid md:grid-cols-3 md:auto-rows-fr md:gap-10">
    <WishlistCard v-for="(wishlist, index) in wishlists"
                  :wishlist="wishlist"
                  :index="index"
                  :mode="WishlistCardTypes.EDIT"
                  :key="wishlist.id"
                  class="mb-4 md:mb-0"/>
    <div @click="$emit('openCreateWishlistModal')" class="min-h-[250px] filter hover:brightness-75 active:brightness-50 col-auto bg-black border-primary text-yellow-500 font-bold border-2 rounded-lg p-4 relative hover:cursor-pointer flex items-center justify-center md:col-auto">
      <img class="absolute w-[65%]" alt="Wishlist add icon" src="../../assets/wishlistIconAdd.webp">
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import WishlistCard from "@/components/Dashboard/WishlistCard";
import {WishlistCardTypes} from "@/Enums/WishlistCardTypes";

export default {
  name: "WishlistsTab",
  components: {
    WishlistCard
  },
  setup() {
    const store = useStore();
    const wishlists = computed(() => store.state.wishlist.wishlistMetadata.wishlists);

    return {
      WishlistCardTypes,
      wishlists
    };
  }
}
</script>

<style scoped lang="scss">

</style>