<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  buttonText: String,
  to: String,
  emitEvent: String
})
</script>

<template>
  <router-link v-if="props.to" :to="props.to" class="inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5">
    {{ props.buttonText }}
  </router-link>
  <button v-else @click="$emit(props.emitEvent)" class="inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5">
    {{ props.buttonText }}
  </button>
</template>
