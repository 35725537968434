class UserMetadata {
    constructor() {
        this._username = '';
        this._email = '';
        this._photoURL = null;
        this._coverURL = null;
        this._queryName = '';
        this._isPublic = true;
        this._isFriendsListPublic = true;
        this._showRealName = true;
        this._shoppingList = [];
        this._blockedList = [];
        this._showBirthday = true;
        this._uid = '';
        this._bio = "";
        this._friends = [];
        this._notifications = [];
        this._birthday = '';
        this._firstName = '';
        this._lastName = '';
    }

    get username() {
        return this._username;
    }

    set username(value) {
        this._username = value;
        this._queryName = value.toLowerCase();
    }

    get photoURL() {
        return this._photoURL;
    }

    set photoURL(value) {
        this._photoURL = value;
    }

    get email() {
        return this._email;
    }

    set email(value) {
        this._email = value;
    }

    get coverURL() {
        return this._coverURL;
    }

    set coverURL(value) {
        this._coverURL = value;
    }

    get queryName() {
        return this._queryName;
    }

    get isPublic() {
        return this._isPublic;
    }

    set isPublic(value) {
        this._isPublic = value;
    }

    get isFriendsListPublic() {
        return this._isFriendsListPublic;
    }

    set isFriendsListPublic(value) {
        this._isFriendsListPublic = value;
    }

    get showRealName() {
        return this._showRealName;
    }

    set showRealName(value) {
        this._showRealName = value;
    }

    get shoppingList() {
        return this._shoppingList;
    }

    set shoppingList(value) {
        this._shoppingList = value;
    }

    get blockedList() {
        return this._blockedList;
    }

    set blockedList(value) {
        this._blockedList = value;
    }

    get showBirthday() {
        return this._showBirthday;
    }

    set showBirthday(value) {
        this._showBirthday = value;
    }

    get uid() {
        return this._uid;
    }

    set uid(value) {
        this._uid = value;
    }

    get bio() {
        return this._bio;
    }

    set bio(value) {
        this._bio = value;
    }

    get friends() {
        return this._friends;
    }

    set friends(value) {
        this._friends = value;
    }

    get notifications() {
        return this._notifications;
    }

    set notifications(value) {
        this._notifications = value;
    }

    get birthday() {
        return this._birthday;
    }

    set birthday(value) {
        this._birthday = value;
    }

    get firstName() {
        return this._firstName;
    }

    set firstName(value) {
        this._firstName = value;
    }

    get lastName() {
        return this._lastName;
    }

    set lastName(value) {
        this._lastName = value;
    }

    toJSON() {
        return JSON.parse(JSON.stringify({
            username: this._username,
            email: this._email,
            photoURL: this._photoURL,
            coverURL: this._coverURL,
            queryName: this._queryName,
            isPublic: this._isPublic,
            isFriendsListPublic: this._isFriendsListPublic,
            showRealName: this._showRealName,
            shoppingList: this._shoppingList,
            blockedList: this._blockedList,
            showBirthday: this._showBirthday,
            uid: this._uid,
            bio: this._bio,
            friends: this._friends,
            notifications: this._notifications,
            birthday: this._birthday,
            firstName: this._firstName,
            lastName: this._lastName,
        }));
    }

    fromFirebaseDoc(firebaseUser) {
        this._username = firebaseUser.username;
        this._email = firebaseUser.email;
        this._photoURL = firebaseUser.photoURL;
        this._coverURL = firebaseUser.coverURL;
        this._queryName = firebaseUser.queryName;
        this._isPublic = firebaseUser.isPublic;
        this._isFriendsListPublic = firebaseUser.isFriendsListPublic;
        this._showRealName = firebaseUser.showRealName;
        this._shoppingList = firebaseUser.shoppingList;
        this._blockedList = firebaseUser.blockedList;
        this._showBirthday = firebaseUser.showBirthday;
        this._uid = firebaseUser.uid;
        this._bio = firebaseUser.bio;
        this._friends = firebaseUser.friends;
        this._notifications = firebaseUser.notifications;
        this._birthday = firebaseUser.birthday;
        this._firstName = firebaseUser.firstName;
        this._lastName = firebaseUser.lastName;

        return this;
    }
}

export default UserMetadata;