<template>
  <div v-if="wishlist" class="flex flex-col sm:flex-row">
    <ViewWishlistSidebar :wishlist-prop="wishlist" @updateFilters="updateFilters" />
    <ProductModal :wishlist="wishlist" :mode="ProductModalTypes.ADD" :is-modal-open="isAddItemModalOpen" @close="isAddItemModalOpen = false"/>
    <div v-if="items.length !== 0" class="w-full sm:w-3/4 p-4 grid grid-cols-1 sm:grid-cols-3 gap-10 h-fit">
      <ProductCard v-for="(product, index) in items" :key="`product-${index}-${state.renderKey}`" :product="product" :wishlist="wishlist" :mode="ProductCardTypes.VIEW" @updatePurchaser="updatePurchaser"/>
    </div>
    <div v-else class="w-full sm:w-3/4 p-4 h-fit flex flex-col items-center justify-center space-y-4">
      <h1 class="text-xl sm:text-2xl font-bold text-gray-700">No products found</h1>
      <p class="text-base sm:text-lg text-gray-500">Either there are no products in this wishlist or the filters are too restrictive</p>
    </div>
  </div>
  <div v-else>
    <h1>No wishlist found</h1>
  </div>
</template>

<script>
import {ProductModalTypes} from "@/Enums/ProductModalTypes";
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router'
import ProductCard from "@/components/Product/ProductCard.vue";
import ProductModal from "@/components/Modals/ProductModal.vue";
import {collection, doc, getDoc, getDocs, getFirestore, query, where} from "firebase/firestore";
import ViewWishlistSidebar from "@/views/ViewWishlist/ViewWishlistSidebar.vue";
import {ProductCardTypes} from "@/Enums/ProductCardTypes";
import {useStore} from "vuex";

export default defineComponent({
  name: "ViewWishlistPage",
  components: {ProductModal, ProductCard, ViewWishlistSidebar},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isAddItemModalOpen = ref(false);
    const username = route.params.username.toLowerCase();
    const wishlistIndex = route.params.id - 1;
    const wishlist = ref(null);
    const them = ref(null);
    const me = computed(() => store.state.authentication.userMetadata);
    const activeFilters = ref({})

    const getUserData = async (username) => {
      const userQuery = query(collection(getFirestore(), "users"), where("queryName", "==", username));
      const userSnap = await getDocs(userQuery);

      if (userSnap.empty) {
        throw new Error("No user found with that username");
      }

      if (userSnap.docs.length > 0) {
        return userSnap.docs[0].data();
      } else {
        throw new Error("No user documents found");
      }
    }

    const getWishlistData = async (uid, wishlistIndex) => {
      const wishlistSnap = await getDoc(doc(getFirestore(), "wishlists", uid));

      if (!wishlistSnap.exists()) {
        throw new Error("No wishlist found for that user");
      }

      if (wishlistIndex >= 0 && wishlistIndex < wishlistSnap.data().wishlists.length) {
        return wishlistSnap.data().wishlists[wishlistIndex];
      } else {
        throw new Error("Invalid wishlist index");
      }
    }

    const fetchData = async () => {
      try {
        them.value = await getUserData(username);
        wishlist.value = await getWishlistData(them.value.uid, wishlistIndex);
      } catch (error) {
        console.error(error.message);
      }
    }

    watch(me, (newValue) => {
      if (newValue.username.toLowerCase() === username.toLowerCase()) {
        router.push(`/wishlist/${wishlistIndex + 1}`);
      }
    });

    onMounted(async () => {
      try {
        await fetchData();
      } catch (error) {
        console.error(error.message);
      }
    });

    const items = computed(() => {
      let filteredItems = wishlist.value.items;

      if (activeFilters.value.min) {
        filteredItems = filteredItems.filter(item => item.price >= activeFilters.value.min);
      }
      if (activeFilters.value.max) {
        filteredItems = filteredItems.filter(item => item.price <= activeFilters.value.max);
      }

      if (activeFilters.value.available) {
        if (activeFilters.value.available === "available") {
          filteredItems = filteredItems.filter(item => item.purchaser.length === 0);
        } else if (activeFilters.value.available === "purchased") {
          filteredItems = filteredItems.filter(item => item.purchaser.length > 0);
        }
      }

      return filteredItems;
    });

    const state = reactive({
      renderKey: 0
    })

    const updatePurchaser = (product) => {
      wishlist.value.items = wishlist.value.items.map(item => {
        if (item.id === product.id) {
          return product;
        }
        return item;
      });
      state.renderKey++;
    }

    const updateFilters = (filters) => {
      activeFilters.value = filters;
      state.renderKey++;
    }

    return {
      ProductModalTypes,
      ProductCardTypes,
      updateFilters,
      fetchData,
      updatePurchaser,
      isAddItemModalOpen,
      state,
      wishlist,
      items
    }
  }
})
</script>
