<template>
  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-lg">
      <h2 class="mt-10 pt-5 pb-5 text-center text-2xl font-bold tracking-tight text-white bg-primary">Login</h2>
    </div>

    <div class="relative mt-5 sm:mx-auto sm:w-full sm:max-w-lg">
      <LoadingComponent v-if="isLoading"/>
      <OAuthSignInSlot/>

      <hr class="mt-5 mb-5"/>

      <Form @submit="onSubmit" class="space-y-6 gap-4">
        <!-- Email -->
        <div>
          <Field name="email" :rules="{ required: true }" v-slot="{ field, errors }">
            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email:</label>
            <input v-bind="field" id="email" name="email" type="email"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>
        </div>

        <!-- Password -->
        <div>
          <Field name="password" :rules="{ required: true}" v-slot="{ field, errors }">
            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password:</label>
            <input v-bind="field" id="password" name="password" type="password"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>
        </div>

        <div class="col-span-2 text-center">
          <div class="text-red-500 text-sm mt-2">{{ error }}</div>
          <button
              class="w-[60%] inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5 active:brightness-90"
              type="submit">Login
          </button>
          <div>
            If you need an account, please
            <router-link to="/register"
                         class="inline-block px-2 py-1 text-xs font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5">
              Register
            </router-link>
            Here
          </div>
          <div>
            <router-link to="/forgot-password"
                         class="inline-block px-2 py-1 text-xs font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5">
              Forgot Password
            </router-link>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'
import {defineComponent} from 'vue'
import OAuthSignInSlot from "@/components/Authentication/OAuthSignIn.vue";
import store from "@/store/store";
import LoadingComponent from "@/components/LoadingComponent.vue";
import {Form, Field, defineRule, configure} from "vee-validate";
import {required} from "@vee-validate/rules";
import {camelCaseToSentence} from "@/plugins/veeValidateHelperMethods";
import {AuthActions} from "@/Enums/AuthActions";
import {mapState} from "vuex";

defineRule('required', required);

configure({
  validateOnInput: true,
  generateMessage: (context) => {
    const fieldName = camelCaseToSentence(context.field);
    const messages = {
      required: `This ${fieldName} is required`,
    };

    return messages[context.rule.name] ?? `The ${fieldName} is required`;
  }
});

export default defineComponent({
  name: 'LoginPage',
  components: {LoadingComponent, OAuthSignInSlot, Form, Field},
  computed: {
    ...mapState('authentication', ['error'])
  },
  setup() {
    const isLoading = computed(() => store.state.authentication.isLoading)

    const onSubmit = (event) => {
      store.dispatch("authentication/firebaseAuthenticationHandler", {
        action: AuthActions.SIGN_IN_WITH_EMAIL_AND_PASSWORD,
        params: {
          email: event.email,
          password: event.password
        }
      });
    }

    return {
      onSubmit,
      isLoading
    }
  }
})
</script>