// Authentication constants
export const USERNAME_MAX_LENGTH = 30;
export const FIRST_NAME_MAX_LENGTH = 30;
export const LAST_NAME_MAX_LENGTH = 30;
export const BIO_MAX_LENGTH = 160;
export const USERNAME_LENGTH_MESSAGE = `Username must not exceed ${USERNAME_MAX_LENGTH} characters`;
export const FIRST_NAME_LENGTH_MESSAGE = `First name must not exceed  ${FIRST_NAME_MAX_LENGTH} characters`;
export const LAST_NAME_LENGTH_MESSAGE = `Last name must not exceed ${LAST_NAME_MAX_LENGTH} characters`;
export const BIO_LENGTH_MESSAGE = `Bio must not exceed ${BIO_MAX_LENGTH} characters`;
export const PROFANE_WORD_MESSAGE = 'Profane words are not allowed';

// Wishlist constants
export const WISHLIST_NAME_MAX_LENGTH = 100;
export const WISHLIST_DESCRIPTION_MAX_LENGTH = 250;
export const WISHLIST_NAME_LENGTH_MESSAGE = `Wishlist name is required and must not exceed ${WISHLIST_NAME_MAX_LENGTH} characters`;
export const WISHLIST_DESCRIPTION_LENGTH_MESSAGE = `Wishlist description must not exceed ${WISHLIST_DESCRIPTION_MAX_LENGTH} characters`;

// Product constants
export const PRODUCT_NAME_MAX_LENGTH = 100;
export const PRODUCT_DESCRIPTION_MAX_LENGTH = 250;
export const WHERE_TO_FIND_MAX_LENGTH = 250;
export const PRODUCT_NAME_LENGTH_MESSAGE = `Product name must not exceed ${PRODUCT_NAME_MAX_LENGTH} characters`;
export const PRODUCT_DESCRIPTION_LENGTH_MESSAGE = `Product description must not exceed ${PRODUCT_DESCRIPTION_MAX_LENGTH} characters`;
export const WHERE_TO_FIND_LENGTH_MESSAGE = `Where to find must not exceed ${WHERE_TO_FIND_MAX_LENGTH} characters`;
