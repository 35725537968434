<template>
  <div class="Dashboard__container" v-if="user">
    <ViewDashboardProfileHeader :userMetadataProp="user" @openFriendsTab="selectedTab = 1"/>

    <!-- User is private and not a friend -->
    <div v-if="!user.isPublic && !isFriend">
      <div class="text-center pb-52 pt-24  w-3/4 m-auto">
        <h1 class="text-4xl font-bold">This profile is private</h1>
        <p class="text-lg">This user has set their profile to private. You can't view their profile. Click
          <router-link to="/" class="text-primary hover:underline">here</router-link>
          to go back to the dashboard. If you wish to view this user's profile, you can send them a friend request.
        </p>
      </div>
    </div>
    <!-- User is public or a friend -->
    <div v-else>
      <div class="Dashboard_tabs">
        <div class="grid grid-cols-1 sm:grid-cols-3">
          <div class="col-auto">
            <h1 class="Dashboard-wishlist_tab inline-block mb-3 sm:mb-1 text-2xl sm:text-4xl font-bold hover:cursor-pointer"
                :class="{ 'text-primary' : (selectedTab === 0) }" @click="selectedTab = 0">
              Wishlists
            </h1>
          </div>
          <div v-if="user.isFriendsListPublic" class="col-auto">
            <h1 class="Dashboard-wishlist_tab inline-block mb-3 sm:mb-1 text-2xl sm:text-4xl font-bold hover:cursor-pointer"
                :class="{ 'text-primary' : (selectedTab === 1) }" @click="selectedTab = 1">
              Friends
            </h1>
          </div>
        </div>
      </div>

      <hr class="Dashboard__tab-hr"/>

      <div v-if="selectedTab === 0" class="Dashboard__wishlist-tab-container">
        <ViewWishlistTab :userMetadataProp="user"/>
      </div>
      <div v-else-if="selectedTab === 1 && user.isFriendsListPublic" class="Dashboard__friends-tab-container">
        <ViewFriendsTab :friends="friends" />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import {ref, onMounted, defineComponent, computed, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {collection, getDocs, getFirestore, query, where} from 'firebase/firestore';
import ViewDashboardProfileHeader from "@/components/Dashboard/ViewDashboardProfileHeader.vue";
import ViewWishlistTab from "@/views/Dashboard/ViewWishlistsTab.vue";
import {useStore} from "vuex";
import ViewFriendsTab from "@/views/Dashboard/ViewFriendsTab.vue";

export default defineComponent({
  name: "ViewUserPage",
  components: {
    ViewFriendsTab,
    ViewWishlistTab,
    ViewDashboardProfileHeader
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const username = route.params.username.toLowerCase();
    const user = ref(null);
    const friends = ref([]);
    const selectedTab = ref(0);
    const isFriend = computed(() => {
      const me = store.state.authentication.userMetadata
      const them = user.value;
      return me.friends.find(friend => friend.uid === them.uid);
    });
    const me = computed(() => store.state.authentication.userMetadata);

    watch(me, () => {
      if (me.value && me.value.queryName === username){
        router.push('/');
      }
    });

    onMounted(async () => {
      // Check if user is me
      if (me.value && me.value.queryName === username){
        router.push('/');
      }

      const userQuery = query(collection(getFirestore(), "users"), where("queryName", "==", username));
      const userSnap = await getDocs(userQuery);

      if (userSnap.empty) {
        console.error("No user found with that username");
      } else {
        user.value = userSnap.docs[0].data();
        if (user.value.friends && user.value.friends.length > 0) {
          const queryVal = query(collection(getFirestore(), "users"), where("uid", "in", user.value.friends));
          getDocs(queryVal).then((docs) => {
            docs.forEach(doc => {
              friends.value.push(doc.data())
            })
          })
        }
      }
    });

    return {
      selectedTab,
      isFriend,
      friends,
      user
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/styles/dashboard";
</style>
