<template>
  <div class="z-50">
    <ModalComponent :is-open="props.isModalOpen" @close="$emit('close')">
      <template #header>
        <div class="flex justify-between">
          <h1 class="text-2xl font-bold">Upload Product Image</h1>
          <img class="w-10 h-10 cursor-pointer" alt="Close Modal" src="../../assets/goldCross.webp" @click="$emit('close')"/>
        </div>
      </template>
      <template #content>
        <FileUploader @file-selected="handleFileSelected" />
      </template>
      <template #footer>
        <div class="flex justify-end mt-4">
          <button class="px-4 py-2 bg-primary text-white rounded mr-2" @click="handleOk">SAVE</button>
          <button class="px-4 py-2 bg-primary text-black rounded" @click="$emit('close')">CANCEL</button>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import ModalComponent from "@/components/Modals/ModalComponent.vue";
import FileUploader from "@/components/FileUploader.vue";
import Compressor from "compressorjs";

export default defineComponent({
  name: 'UploadProductImageModal',
  components: {FileUploader, ModalComponent},
  props: {
    isModalOpen: Boolean
  },
  setup(props, context) {
    const photo = ref(null);
    const preview = ref(null);

    const handleOk = () => {
      context.emit('close');
      // Attempt to compress file.
      new Compressor(photo.value, {
        maxHeight: 550,
        quality: 0.65,
        success(compressedFile) {
          context.emit('fileSelected', {file: compressedFile, previewURL: preview.value});
        },
        error(error) {
          // Upload anyway.
          console.error(error)
          context.emit('fileSelected', {file: photo.value, previewURL: preview.value});
        }
      })
    };

    const handleFileSelected = ({file, previewURL}) => {
      // Handle the uploaded file here
      photo.value = file;
      preview.value = previewURL;
    };

    return {
      handleFileSelected,
      handleOk,
      props
    }
  }
})
</script>