<template>
  <div class="relative">
    <LoadingComponent v-if="isLoading"/>
    <h1 class="text-4xl font-bold">General</h1>
    <div v-if="initialValues">
      <Form v-slot="{ validate }" :initial-values="initialValues" @submit="onSubmit">
        <!-- First name -->
        <Field name="firstName" :rules="{ required: true, max: constants.FIRST_NAME_MAX_LENGTH }"
               v-slot="{ field, errors }" @input="validate">
          <div class="mt-4 flex items-center">
            <label for="firstName" class="block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[120px]">
              <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Enter your first name here" />First Name:</label>
            <input v-bind="field" id="firstName" name="firstName" type="text"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6">
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-md mt-2">{{ error }}</div>
          </div>
        </Field>

        <!-- Last name -->
        <Field name="lastName" :rules="{ required: true, max: constants.LAST_NAME_MAX_LENGTH }"
               v-slot="{ field, errors }" @input="validate">
          <div class="mt-4 flex items-center">
            <label for="firstName" class="block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[120px]">
              <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Enter your last name here" />Last Name:</label>
            <input v-bind="field" id="lastName" name="lastName" type="text"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6">
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-md mt-2">{{ error }}</div>
          </div>
        </Field>

        <!-- Username -->
        <Field name="username" :rules="{ required: true, max: constants.USERNAME_MAX_LENGTH, noSpaces: true}"
               v-slot="{ field, errors }" @input="validate">
          <div class="mt-4 flex items-center">
            <label for="firstName" class="block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[120px]">
              <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Enter your username here" />Username:</label>
            <input v-bind="field" id="username" name="username" type="text"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6">
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-md mt-2">{{ error }}</div>
          </div>
        </Field>

        <!-- Birthday -->
        <Field name="birthday" :rules="{ required: true }" v-slot="{ field, errors }" @input="validate">
          <div class="mt-4 flex items-center">
            <label for="firstName" class="block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[120px]">
              <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Enter your birthday here" />Birthday:</label>
            <input v-bind="field" id="birthday" name="birthday" type="date"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6">
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-md mt-0.5">{{ error }}</div>
          </div>
        </Field>

        <!-- Bio -->
        <Field name="bio" :rules="{ required: false, max: constants.BIO_MAX_LENGTH }" v-slot="{ field, errors }" @input="validate">
          <div class="mt-4 flex items-center">
            <label for="firstName" class="block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[120px]">
              <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Enter your profile bio here" />Biography:</label>
            <textarea v-bind="field" id="bio" name="bio"
                      class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"></textarea>
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-md mt-2">{{ error }}</div>
          </div>
        </Field>

        <div class="input-errors" v-if="error">
          <div class="text-red-500 text-md mt-2">{{ error }}</div>
        </div>

        <div class="my-5">
          <button type="reset"
                  class="bg-gray-500 mr-1 text-white p-2 rounded mt-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
            RESET
          </button>

          <button type="submit"
                  class="bg-primary ml-1 text-white p-2 rounded mt-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
            SAVE CHANGES
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script lang="js">
import { toast } from 'vue3-toastify';
import {defineComponent, ref, watch} from 'vue';
import {Form, Field, defineRule, configure} from "vee-validate";
import * as constants from "@/constants";
import {camelCaseToSentence, noSpaces} from "@/plugins/veeValidateHelperMethods";
import {max, required} from "@vee-validate/rules";
import {mapState, useStore} from "vuex";
import _ from "lodash";
import LoadingComponent from "@/components/LoadingComponent.vue";

defineRule('required', required);
defineRule('max', max);
defineRule('noSpaces', noSpaces);

configure({
  validateOnInput: true,
  generateMessage: (context) => {
    const fieldName = camelCaseToSentence(context.field);
    const messages = {
      required: `The ${fieldName} field is required.`,
      max: `The ${fieldName} field may not be greater than ${context.rule.params[0]} characters.`,
      noSpaces: `The ${fieldName} field may not contain spaces.`
    };
    return messages[context.rule.name] ?? `The ${fieldName} field is invalid.`;
  }
});

export default defineComponent({
  name: "GeneralTab",
  components: {
    LoadingComponent,
    Form, Field
  },
  computed: {
    ...mapState('authentication', ['error', 'isLoading'])
  },
  setup() {
    const store = useStore();
    const initialValues = ref(null);
    const currentValues = ref(null);

    watch(() => store.state.authentication.userMetadata, (newVal) => {
      if (_.isEmpty(newVal)) return;
      const userData = newVal.toJSON();
      initialValues.value = userData
      currentValues.value = _.cloneDeep(userData);
    }, {immediate: true});

    const onSubmit = (values) => {
      if (!_.isEqual(values, currentValues.value)) {
        store.dispatch('authentication/firebaseUpdateProfile', { user: values });
        currentValues.value = _.cloneDeep(values);
      } else {
        toast('No changes detected', {
          position: 'bottom-right',
          autoClose: 2000
        });
      }
    }

    return {
      onSubmit,
      initialValues,
      constants
    }
  }
});
</script>