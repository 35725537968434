<template>
  <div class="bg-white border-primary border-2 mt-5">
    <!-- Search bar -->
    <div class="flex flex-row items-center justify-center">
      <input v-model="query" @input="searchFriends" type="text" class="p-2 m-5 mt-10 w-[80%] rounded border-primary border-2" placeholder="Search friends..."/>
    </div>
    <!-- Friends -->
    <FriendCard v-for="(friend, index) in currentItems" :key="`friend-${index}`" :friend-prop="friend" @removeFriend="removeFriend(friend)"/>
    <!-- Pagination -->
    <div class="flex flex-row items-center justify-center">
      <font-awesome-icon @click="page = 1"
                         :class="{'text-primary': page === 1, 'hover:text-primary': page !== 1}"
                         class="m-2 cursor-pointer"
                         :icon="['fas', 'angle-double-left']"/>
      <button
          v-for="pageNumber in numberOfPages"
          :key="pageNumber"
          @click="page = pageNumber"
          :class="{
        'bg-primary': page === pageNumber,
        'text-white': page === pageNumber,
        'hover:bg-primary': page !== pageNumber,
        'hover:text-white': page !== pageNumber
      }"
          class="py-2 px-5 m-2 rounded cursor-pointer hover:brightness-75 hover:text-white active:brightness-50"
      >
        {{ pageNumber }}
      </button>
      <font-awesome-icon @click="page = numberOfPages"
                         :class="{'text-primary': page === numberOfPages, 'hover:text-primary': page !== numberOfPages}"
                         class="m-2 cursor-pointer"
                         :icon="['fas', 'angle-double-right']"/>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, ref, watch} from 'vue';
import {useStore} from "vuex";
import FriendCard from "@/components/Dashboard/FriendCard.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: "FriendsTab",
  components: {FontAwesomeIcon, FriendCard},
  setup() {
    const store = useStore()
    const itemsPerPage = 10
    const page = ref(1)
    const fullFriendsList = ref(store.state.authentication.friendsMetadataObjects)
    const query = ref('')
    const friends = computed(() => store.state.authentication.friendsMetadataObjects)
    const numberOfPages = computed(() => {
      return Math.ceil(store.state.authentication.friendsMetadataObjects.length / itemsPerPage)
    })

    watch(friends, (newValue) => {
      fullFriendsList.value = newValue
    })

    const currentItems = computed(() => {
      const start = (page.value - 1) * itemsPerPage
      const end = start + itemsPerPage
      return fullFriendsList.value.slice(start, end)
    })

    const searchFriends = () => {
      fullFriendsList.value = store.state.authentication.friendsMetadataObjects.filter(friend => {
        let userFound = false
        // Check usernames
        if (friend.username.toLowerCase().includes(query.value.toLowerCase())) {
          userFound = true
        }
        // Check first names
        if (friend.firstName.toLowerCase().includes(query.value.toLowerCase())) {
          userFound = true
        }
        // Check last names
        if (friend.lastName.toLowerCase().includes(query.value.toLowerCase())) {
          userFound = true
        }
        return userFound
      })
    }

    const removeFriend = (friend) => {
      store.dispatch('authentication/unFriendUser', {
        them: friend
      })
    }

    return {
      removeFriend,
      searchFriends,
      query,
      currentItems,
      page,
      numberOfPages
    }
  },
});
</script>