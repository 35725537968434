<template>
  <div class="relative flex justify-center items-center p-5">
    <ConfirmationModal :is-modal-open="isConfirmModalOpen" @close="isConfirmModalOpen = false" @confirmed="deleteWishlist"
                       header="Delete Wishlist"
                       body="Are you sure you want to delete this wishlist? This action cannot be undone."
                       confirmButtonText="Delete"/>
    <LoadingComponent v-if="isLoading"/>
    <Form :initial-values="wishlist" @submit="onSubmit"  class="w-full">

      <!-- Wishlist Name -->
      <div class="mb-4">
        <Field name="name" :rules="{ required: true, max: constants.WISHLIST_NAME_MAX_LENGTH }" v-slot="{ field, errors }">
          <label class="block text-lg font-bold mb-2" for="name">Name</label>
          <input v-bind="field" id="name" name="name" type="text"
                 class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-sm mt-2">{{ error }}</div>
          </div>
        </Field>
      </div>

      <!-- Wishlist Date -->
      <div class="mb-4">
        <Field name="date" :rules="{ required: false }" v-slot="{ field, errors }">
          <label class="block text-lg font-bold mb-2" for="date">Date</label>
          <input v-bind="field" id="date" name="date" type="date"
                 class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-sm mt-2">{{ error }}</div>
          </div>
        </Field>
      </div>

      <!-- Wishlist Description -->
      <div class="mb-4">
        <Field name="description" :rules="{ required: false, max: constants.WISHLIST_DESCRIPTION_MAX_LENGTH }" v-slot="{ field, errors }">
          <label class="block text-lg font-bold mb-2" for="description">Description</label>
          <textarea v-bind="field" id="description" name="description"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-sm mt-2">{{ error }}</div>
          </div>
        </Field>
      </div>

      <!-- Wishlist Privacy -->
      <div class="mb-4">
        <Field name="privacy" :rules="{ required: false }" v-slot="{ field, errors }">
          <label class="block text-lg font-bold mb-2" for="privacy">Privacy</label>
          <select v-bind="field" id="privacy" name="privacy"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            <option v-for="privacyType in privacyTypes" :key="privacyType" :value="privacyType">
              {{ privacyType }}
            </option>
          </select>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-sm mt-2">{{ error }}</div>
          </div>
        </Field>
      </div>

      <!-- Wishlist Theme -->
      <div class="mb-4">
        <Field name="theme" :rules="{ required: true }" v-slot="{ errors }">
          <label class="block text-lg font-bold mb-1" for="theme">Theme</label>
          <div class="flex items-center">
            <select v-model="selectedTheme" id="theme" name="theme"
                    class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <option v-for="theme in themes" :key="theme.name" :value="theme">{{ theme.text }}</option>
            </select>
            <img class="border-primary border-2 ms-2 rounded w-16 h-12 object-contain inline-block p-1 py-1.5"
                 :src="require(`../../assets/wishlistIcons/${selectedTheme.name}.svg`)"
                 alt="Selected Icon"/>
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-sm mt-2">{{ error }}</div>
          </div>
        </Field>
      </div>
      <div>
        <button
            class="w-[100%] block px-2 py-1 text-md font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5"
            type="submit">
          SAVE CHANGES
        </button>
        <button
            class="w-[100%] block px-2 py-1 text-md font-medium leading-6 text-center text-white uppercase transition bg-gray-500 rounded shadow ripple hover:shadow-lg hover:bg-gray-600 focus:outline-none mt-5"
            type="reset">
          CANCEL CHANGES
        </button>
      </div>
      <hr class="mt-5 mb-5 border-black"/>
      <div>
        <button @click="$emit('openAddItemModal')"
                class="w-[100%] block px-2 py-1 text-md font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5"
                type="button">
          ADD ITEM TO WISHLIST
        </button>
        <button
            class="w-[100%] block px-2 py-1 text-md font-medium leading-6 text-center text-white uppercase transition bg-gray-500 rounded shadow ripple hover:shadow-lg hover:bg-gray-600 focus:outline-none mt-5"
            type="button"
            @click="isConfirmModalOpen = true">
          DELETE WISHLIST
        </button>
      </div>
    </Form>
  </div>
</template>

<script>
import {computed, ref, watch} from 'vue'
import {WishlistPrivacyTypes} from "@/Enums/WishlistPrivacyTypes";
import {themeTypes} from "@/Enums/ThemeTypes";
import LoadingComponent from "@/components/LoadingComponent.vue";
import * as constants from "@/constants";
import {Form, Field, defineRule, configure} from 'vee-validate';
import {required, max} from '@vee-validate/rules';
import {camelCaseToSentence} from "@/plugins/veeValidateHelperMethods";
import {isEqual} from "lodash";
import Wishlist from "@/entities/Wishlist";
import {useStore} from "vuex";
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";

defineRule('required', required);
defineRule('max', max);

configure({
  validateOnInput: true,
  generateMessage: (context) => {
    const fieldName = camelCaseToSentence(context.field);
    const messages = {
      required: `${fieldName} is required`,
      max: `${fieldName} must be at most ${context.value} characters`,
    };

    return messages[context.rule.name] ?? `${fieldName} is invalid`;
  }
});

export default {
  name: 'EditWishlistDetailForm',
  props: {
    wishlistProp: {
      type: Object,
      required: true
    }
  },
  components: {ConfirmationModal, LoadingComponent, Form, Field},
  setup(props) {
    const store = useStore()
    const isLoading = computed(() => store.state.wishlist.isLoading)
    const privacyTypes = ref(Object.values(WishlistPrivacyTypes));
    const wishlist = ref(props.wishlistProp);
    const isChanged = ref(false);
    const isConfirmModalOpen = ref(false);
    const themes = ref(themeTypes);
    const selectedTheme = ref(props.wishlistProp.theme);

    watch(wishlist, () => {
      if (!isChanged.value) {
        isChanged.value = !isEqual(wishlist.value, props.wishlistProp);
      }
    }, {deep: true});

    const deleteWishlist = () => {
      store.dispatch('wishlist/deleteWishlist', props.wishlistProp.id);
    };

    const onSubmit = (event) => {
      const edited = new Wishlist();
      edited.id = props.wishlistProp.id;
      edited.name = event.name;
      edited.date = event.date ?? null;
      edited.description = event.description ?? '';
      edited.privacy = event.privacy;
      edited.theme = selectedTheme.value;
      edited.items = props.wishlistProp.items;
      edited.owner = store.state.authentication.userMetadata.uid;
      store.dispatch('wishlist/updateWishlist', edited.toJSON());
    };

    return {
      deleteWishlist,
      onSubmit,
      isConfirmModalOpen,
      constants,
      isLoading,
      wishlist,
      isChanged,
      privacyTypes,
      selectedTheme,
      themes
    }
  }
}
</script>