<template>
  <div class="Dashboard__container">
    <!-- Modals -->
    <UploadProfilePictureModal :is-modal-open="isOpenUploadProfilePictureModal" @close="isOpenUploadProfilePictureModal = false"/>
    <UploadCoverPictureModal :is-modal-open="isOpenUploadCoverPictureModal" @close="isOpenUploadCoverPictureModal = false" />
    <CreateWishlistModal :is-modal-open="isOpenCreateWishlistModal" @close="isOpenCreateWishlistModal = false"/>
    <DashboardProfileHeader :userMetadataProp="userMetadata"
                            @openCreateWishlistModal="isOpenCreateWishlistModal = true"
                            @openUploadProfilePictureModal="isOpenUploadProfilePictureModal = true"
                            @openUploadCoverPictureModal="isOpenUploadCoverPictureModal = true"
                            @openFriendsTab="selectedTab = 'friends'"/>

    <div class="Dashboard_tabs">
      <div class="flex flex-col md:grid md:grid-cols-3">
        <div class="mb-4 md:mb-0 md:col-auto">
          <h1 class="Dashboard-wishlist_tab inline-block mb-1 text-4xl font-bold hover:cursor-pointer"
              :class="{ 'text-primary' : (selectedTab === 'wishlists') }" @click="selectTab('wishlists')">
            Wishlists
          </h1>
        </div>
        <div class="mb-4 md:mb-0 md:col-auto">
          <h1 class="Dashboard-wishlist_tab inline-block mb-1 text-4xl font-bold hover:cursor-pointer"
              :class="{ 'text-primary' : (selectedTab === 'friends') }" @click="selectTab('friends')">
            Friends
          </h1>
        </div>
        <div class="md:col-auto">
          <h1 class="Dashboard-wishlist_tab inline-block mb-1 text-4xl font-bold hover:cursor-pointer"
              :class="{ 'text-primary' : (selectedTab === 'shopping') }" @click="selectTab('shopping')">
            Shopping List
          </h1>
        </div>
      </div>
    </div>

    <hr class="Dashboard__tab-hr"/>

    <div v-if="selectedTab === 'wishlists'" class="Dashboard__wishlist-tab-container">
      <WishlistsTab @openCreateWishlistModal="isOpenCreateWishlistModal = true" />
    </div>
    <div v-else-if="selectedTab === 'friends'" class="Dashboard__friends-tab-container">
      <FriendsTab />
    </div>
    <div v-else-if="selectedTab === 'shopping'" class="Dashboard__shopping-tab-container">
<!--
      <ShoppingListTab :shopping-list="userMetadata.shoppingList" />
-->
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, ref} from 'vue';
import DashboardProfileHeader from "@/components/Dashboard/DashboardProfileHeader.vue";
import UploadProfilePictureModal from "@/components/Modals/UploadProfilePictureModal.vue";
import UploadCoverPictureModal from "@/components/Modals/UploadCoverPictureModal.vue";
import CreateWishlistModal from "@/components/Modals/CreateWishlistModal.vue";
import WishlistsTab from "@/views/Dashboard/WishlistsTab.vue";
import store from "@/store/store";
import FriendsTab from "@/views/Dashboard/FriendsTab.vue";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  name: "DashboardPage",
  components: {
    FriendsTab,
    WishlistsTab,
    CreateWishlistModal,
    UploadCoverPictureModal,
    UploadProfilePictureModal,
    DashboardProfileHeader,
  },
  setup() {
    const userMetadata = computed(() => store.state.authentication.userMetadata);
    const route = useRoute();
    const router = useRouter();
    const selectedTab = ref(route.query.tab || 'wishlists');
    const activeItem = ref(null);
    const outdatedWishlists = ref([]);
    let isOpenUploadProfilePictureModal = ref(false)
    let isOpenUploadCoverPictureModal = ref(false)
    let isOpenCreateWishlistModal = ref(false)

    const selectTab = (tabName) => {
      selectedTab.value = tabName;
      router.push({ query: { ...route.query, tab: tabName } });
    };

    return {
      selectTab,
      userMetadata,
      selectedTab,
      activeItem,
      outdatedWishlists,
      isOpenUploadProfilePictureModal,
      isOpenUploadCoverPictureModal,
      isOpenCreateWishlistModal
    }
  }
});
</script>


<style lang="scss" scoped>
@import "~@/styles/dashboard";

.ViewWishlist__tab-wishlist-container {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

@media screen and (max-width: $xLarge) {
  .ViewWishlist__tab-wishlist-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: $large) {
  .ViewWishlist__tab-wishlist-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>