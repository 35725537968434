<template>
  <div>
    <!-- Sidebar -->
    <div :class="{ 'translate-x-full': !props.isSidebarOpen }" class="z-50 fixed right-0 top-0 h-full w-[34rem] bg-marble p-4 transition-all transform border-l-primary border-l-2">
      <div class="flex justify-between items-center">
        <h2 class="text-3xl text-primary font-bold">Notifications</h2>
        <img class="w-10 h-10 cursor-pointer" alt="Close Modal" src="../../assets/goldCross.webp"
             @click="$emit('close')"/>
      </div>
      <hr class="border-primary border-1 mt-2 mb-2 w-full" />
      <div v-if="notifications.length > 0">
        <div v-if="friendRequests.length > 0" class="text-white">
          <h1 class="text-lg font-bold">Friend Requests</h1>
          <NotificationFriendRequest v-for="notification in notifications" :key="notification" :notification-prop="notification"/>
        </div>
      </div>
      <div v-else class="text-center text-lg text-primary">No notifications</div>
    </div>

    <!-- Overlay -->
    <div v-show="isSidebarOpen" class="fixed inset-0 bg-black opacity-50 z-40" @click="$emit('close')"></div>
  </div>
</template>

<script lang="js">
import {useStore} from "vuex";
import {computed} from "vue";
import {NotificationTypes} from "@/Enums/NotificationTypes";
import NotificationFriendRequest from "@/components/Navigation/NotificationComponents/NotificationFriendRequest.vue";

export default {
  name: 'NotificationSidebar',
  components: {NotificationFriendRequest},
  props: {
    isSidebarOpen: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const notifications = computed(() => {
      if (store.state.authentication.userMetadata
          && store.state.authentication.userMetadata.notifications) {
        return store.state.authentication.userMetadata.notifications
      } else {
        return []
      }
    })
    const friendRequests = computed(() => {
      return notifications.value.filter(notification => notification.type === NotificationTypes.FRIEND_REQUEST)
    })
    return {
      notifications,
      friendRequests,
      props
    }
  }
}
</script>