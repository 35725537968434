<template>
  <nav
      class="font-sans flex flex-col text-center sm:flex-row sm:text-left sm:justify-between py-4 px-6 sm:items-baseline w-full bg-transparent">
    <div class="mb-2 sm:mb-0">
      <router-link to="/" class="text-2xl sm:text-4xl no-underline text-grey-darkest hover:text-blue-dark text-primary font-bold">
        Gimmy
      </router-link>
    </div>
    <div>
      <div v-if="firebaseUser" class="w-full sm:w-[20rem] inline-block mx-auto text-gray-600">
        <div v-on-click-outside="hideDropdown" class="relative" ref="target">
          <input class="border-2 w-full border-primary bg-white h-10 px-5 pr-10 rounded-lg text-lg focus:outline-none"
                 type="search" name="search" placeholder="Username search" v-model="searchQuery"
                 @focusin="(searchResults.length > 0) ? searchDropdown = true : null"
                 @input="hideDropdown"
                 @keyup.enter="search">
          <button @click="search"
                  class="absolute top-0 right-0 mt-2 mr-2 bg-primary px-2 rounded text-white hover:brightness-90 active:brightness-75"
                  type="button">
            Search
          </button>
          <div class="z-20 border-primary border-2 absolute top-full mt-2 w-full bg-white rounded-lg shadow-lg"
               v-show="searchDropdown">
            <div v-if="isSearching">
              <div class="relative h-[4rem]">
                <LoadingComponent class="bg-white" />
              </div>
            </div>
            <div v-else-if="searchResults.length === 0" class="p-4">
              <h2 class="text-center">No results found</h2>
            </div>
            <div v-else v-for="(result, index) in searchResults" :key="index">
              <router-link :to="`/user/${result.username}`" @click="hideDropdown">
                <ResultRow :user="result" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <router-link to="/learnmore" class="text-xl sm:text-2xl no-underline text-grey-darkest hover:text-blue-dark m-2 sm:m-5">Learn More
      </router-link>
      <router-link v-if="firebaseUser" to="/" class="text-xl sm:text-2xl no-underline text-grey-darkest hover:text-blue-dark m-2 sm:m-5">
        Profile
      </router-link>
      <router-link v-if="!firebaseUser" to="/login"
                   class="text-xl sm:text-2xl no-underline text-grey-darkest hover:text-blue-dark m-2 sm:m-5">Login
      </router-link>
      <router-link v-if="!firebaseUser" to="/register"
                   class="text-xl sm:text-2xl no-underline text-grey-darkest hover:text-blue-dark m-2 sm:m-5">Register
      </router-link>
      <div v-else class="relative inline-block text-left">
        <button class="text-2xl no-underline text-grey-darkest hover:text-blue-dark m-5"
                @click="profileDropDownOpen = !profileDropDownOpen">Settings<font-awesome-icon :icon="(profileDropDownOpen) ? 'fa-chevron-up' : 'fa-chevron-down'"
                                                                                           class="text-primary text-xl ms-2"/>
        </button>
        <ConfirmationModal :is-modal-open="isConfirmModalOpen" @close="isConfirmModalOpen = false" @confirmed="signOut"
                           header="Sign out"
                           body="Are you sure you want to sign out?"
                           confirmButtonText="Sign out"/>
        <transition name="slide-fade">
          <div v-show="profileDropDownOpen" ref="target"
               class="dropdown-menu z-50 origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" @click="profileDropDownOpen = false">
              <router-link to="/" class="text-center text-primary w-full block px-4 py-2 text-lg hover:bg-gray-100" role="menuitem">Dashboard
              </router-link>
              <router-link to="/account"
                           class="text-center text-primary w-full block px-4 py-2 text-lg hover:bg-gray-100"
                           role="menuitem">Edit
                Account
              </router-link>
              <button class="text-primary w-full block px-4 py-2 text-lg hover:bg-gray-100" role="menuitem"
                      @click="isConfirmModalOpen = true">Sign out
              </button>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="firebaseUser" @click="$emit('openNotificationSidebar')"
           class="p-2 relative inline-block hover:cursor-pointer hover:brightness-90 active:brightness-75">
        <font-awesome-icon icon="fa-bell" class="text-primary text-4xl"/>
        <span v-if="notificationCount > 0"
              class="absolute top-0 right-0 inline-block bg-black text-white text-sm px-1 rounded-full">{{
            notificationCount
          }}</span>
      </div>
    </div>
  </nav>
</template>

<script>
import {vOnClickOutside} from '@vueuse/components'
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import LoadingComponent from "@/components/LoadingComponent.vue";
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";
import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
import ResultRow from "@/components/Navigation/ResultRow.vue";
import {onClickOutside} from "@vueuse/core";

export default {
  name: 'NavigationBar',
  components: {ResultRow, ConfirmationModal, LoadingComponent},
  directives: {
    onClickOutside: vOnClickOutside
  },
  setup() {
    const store = useStore()
    const profileDropDownOpen = ref(false)
    const searchDropdown = ref(false)
    const isSearching = ref(false)
    const isConfirmModalOpen = ref(false)
    const searchQuery = ref('')
    const target = ref(null)
    onClickOutside(target, () => profileDropDownOpen.value = false)
    const searchResults = ref([])
    const search = async () => {
      searchResults.value = []
      isSearching.value = true
      searchDropdown.value = true
      const userQuery = query(collection(getFirestore(), "users"), where("searchIndex", "array-contains", searchQuery.value.toLowerCase()));
      await getDocs(userQuery).then((docs) => {
        docs.forEach(doc => {
          const them = doc.data()
          if (them.isPublic) {
            searchResults.value.push(them)
          }
        })
      })
      isSearching.value = false
    }
    const firebaseUser = computed(() => store.state.authentication.firebaseUser)
    const notificationCount = computed(() => {
      if (store.state.authentication.userMetadata
          && store.state.authentication.userMetadata.notifications) {
        return store.state.authentication.userMetadata.notifications.length
      } else {
        return 0
      }
    })

    const signOut = () => {
      profileDropDownOpen.value = false
      store.dispatch('authentication/firebaseSignOut')
    }

    const hideDropdown = () => {
      searchDropdown.value = false
    }

    return {
      hideDropdown,
      signOut,
      search,
      target,
      isConfirmModalOpen,
      isSearching,
      searchDropdown,
      searchResults,
      searchQuery,
      profileDropDownOpen,
      firebaseUser,
      notificationCount
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all .2s ease;
}

.slide-fade-leave-active {
  transition: all .2s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transition: all .2s ease;
  transform: translateY(-10px);
  opacity: 0;
}

.dropdown-menu {
  background-image: url(~@/assets/blackMarble.webp);
  background-size: cover;
  background-position: center;
}
</style>