<template>
  <ModalComponent :is-open="isModalOpen" @close="$emit('close')">
    <template v-slot:header>
      <h1 class="w-full text-center text-2xl sm:text-3xl">Share Wishlist</h1>
    </template>
    <template v-slot:content>
      <div class="flex flex-col items-center">
        <h2 class="text-lg sm:text-xl">Share this wishlist with friends</h2>
        <input type="text" :value="viewWishlistURL" disabled class="w-full sm:w-1/2 p-2 mt-4 border-2 border-gray-400 rounded"/>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-end">
        <button @click="copyToClipboard" class="text-white px-4 sm:px-8 py-1 sm:py-2 rounded bg-primary m-2 sm:m-4 text-base sm:text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
          Copy Link
        </button>
        <button @click="$emit('close')" type="reset"
                class="text-white px-4 sm:px-8 py-1 sm:py-2 rounded bg-gray-400 m-2 sm:m-4 text-base sm:text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
          close
        </button>
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import {computed, defineComponent} from 'vue';
import ModalComponent from "@/components/Modals/ModalComponent.vue";
import {useStore} from "vuex";
import {toast} from "vue3-toastify";

export default defineComponent({
  name: "ShareWishlistModal",
  components: {ModalComponent},
  props: {
    isModalOpen: Boolean,
    wishlist: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const userMetadata = computed(() => store.state.authentication.userMetadata)
    const viewWishlistURL = computed(() => {
      return `${window.location.origin}/wishlist/${userMetadata.value.username}/${props.index + 1}`
    })
    const copyToClipboard = () => {
      try {
        navigator.clipboard.writeText(viewWishlistURL.value)
        toast.success('Copied to clipboard', {
          position: 'bottom-right',
          autoClose: 2000
        })
      } catch (e) {
        toast.error('Failed to copy to clipboard', {
          position: 'bottom-right',
          autoClose: 2000
        })
      }
    }
    return {
      copyToClipboard,
      viewWishlistURL
    }
  }
});
</script>