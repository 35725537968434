<template>
  <div class="w-full md:w-1/4 md:min-h-fit lg:min-h-screen">
    <ViewWishlistDetailForm @updateFilters="updateFilters" />
  </div>
</template>

<script>
import ViewWishlistDetailForm from "@/views/ViewWishlist/ViewWishlistDetailForm.vue";

export default {
  name: "WishlistSidebar",
  components: {ViewWishlistDetailForm},
  props: {
    wishlistProp: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const updateFilters = (filters) => {
      context.emit('updateFilters', filters);
    }

    return {
      updateFilters,
      props
    };
  },
}
</script>
