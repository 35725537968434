<template>
  <div>
    <ModalComponent :is-open="props.isModalOpen" @close="$emit('close')" @submit="$emit('close')" name="first-modal">
      <template #header>
        <div class="flex justify-between">
          <h1 class="text-2xl font-bold">Upload Profile Picture</h1>
          <img class="w-10 h-10 cursor-pointer" alt="Close Modal" src="../../assets/goldCross.webp" @click="$emit('close')"/>
        </div>
      </template>
      <template #content>
        <FileUploader @file-selected="handleFileSelected" :is-rounded="true"/>
      </template>
      <template #footer>
        <div class="flex justify-end mt-4">
          <button class="px-4 py-2 bg-primary text-white rounded mr-2" @click="handleOk">SAVE</button>
          <button class="px-4 py-2 bg-primary text-black rounded" @click="$emit('close')">CANCEL</button>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import ModalComponent from "@/components/Modals/ModalComponent.vue";
import Compressor from "compressorjs"
import FileUploader from "@/components/FileUploader.vue";
import store from "@/store/store";

export default defineComponent({
  name: "UploadProfilePictureModal",
  components: {FileUploader, ModalComponent},
  props: {
    isModalOpen: Boolean
  },
  setup(props, context) {
    const photo = ref(null);

    const handleOk = () => {
      context.emit('close');
      // Attempt to compress file.
      new Compressor(photo.value, {
        maxHeight: 550,
        quality: 0.65,
        success(compressedFile) {
          store.dispatch('authentication/uploadPhoto', {photo: compressedFile, storagePath: 'profileImages', mutationType: 'setProfileURL'});
        },
        error(error) {
          // Upload anyway.
          console.error(error)
          store.dispatch('authentication/uploadPhoto', {photo: photo.value, storagePath: 'profileImages', mutationType: 'setProfileURL'});
        }
      })
    };

    const handleFileSelected = ({file}) => {
      // Handle the uploaded file here
      photo.value = file;
    };

    return {
      photo,
      props,
      handleFileSelected,
      handleOk
    }
  }
})
</script>