<template>
  <div class="flex items-center p-4 hover:brightness-90 active:brightness-75 bg-white cursor-pointer">
    <img class="h-12 w-12 rounded-full bg-black" :src="(user.photoURL) ? user.photoURL : ProfilePhotoPlaceholder" alt="profile photo" @click="userSelected" />
    <div class="ms-5">
      <h3 class="font-bold text-black">{{ user.username }}</h3>
      <h3 v-if="user.showRealName">{{ user.firstName }} {{ user.lastName }}</h3>
    </div>
  </div>
</template>

<script>
import ProfilePhotoPlaceholder from "@/assets/profilePhotoPlaceholder.webp";
import { defineComponent } from 'vue';

export default defineComponent({
  name: "ResultRow",
  props: {
    user: {
      type: Object,
      required: true
    },
  },
  setup() {
    const userSelected = () => {
      console.log('userSelected');
    };

    return {
      userSelected,
      ProfilePhotoPlaceholder
    }
  }
});
</script>