<template>
  <div class="text-center">
    <div>
      <button @click="signInWithFacebook" class="OAuth__button OAuth__button--facebook">
        Login with Facebook
      </button>

      <button @click="signInWithGoogle" class="OAuth__button OAuth__button--google">
        Login with Google
      </button>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {FacebookAuthProvider, GoogleAuthProvider} from "firebase/auth";
import {AuthActions} from "@/Enums/AuthActions";
import store from "@/store/store";

export default defineComponent({
  name: "OAuthSignInSlot",
  setup() {
    const signInWithFacebook = () => {
      store.dispatch("authentication/firebaseAuthenticationHandler", {action: AuthActions.SIGN_IN_WITH_PROVIDER, params: {provider: new FacebookAuthProvider()}});
    };

    const signInWithGoogle = () => {
      store.dispatch("authentication/firebaseAuthenticationHandler", {action: AuthActions.SIGN_IN_WITH_PROVIDER, params: {provider: new GoogleAuthProvider()}});
    };
    return {
      signInWithFacebook,
      signInWithGoogle
    }
  }
})
</script>

<style lang="scss" scoped>
.OAuth__button {
  box-sizing: border-box;
  position: relative;
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}
.OAuth__button:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.OAuth__button:focus {
  outline: none;
}
.OAuth__button:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}


/* Facebook */
.OAuth__button--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  text-shadow: 0 -1px 0 #354C8C;
}
.OAuth__button--facebook:hover,
.OAuth__button--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}

/* Google */
.OAuth__button--google {
  background: #DD4B39;
}
.OAuth__button--google:hover,
.OAuth__button--google:focus {
  background: #E74B37;
}

.OAuth__button--facebook:before {
  border-right: #364e92 1px solid;
  background: url("../../assets/authIcons/icon_facebook.png") 6px 6px no-repeat;
}
.OAuth__button--google:before {
  border-right: #BB3F30 1px solid;
  background: url("../../assets/authIcons/icon_google.png") 6px 6px no-repeat;
}
</style>