<template>
  <ModalComponent class="w-36" :is-open="isModalOpen" @close="$emit('close')">
    <template v-slot:header>
      <h1 class="w-full text-center text-5xl">Delete Account</h1>
    </template>
    <template v-slot:content>
      <h1 class="text-2xl mt-5 mb-2 font-bold">Are you sure you want to delete your account?</h1>
      <p class="block text-lg">
        This will have a cascade effect and will delete all photos, wishlists, items and anything associated with your
        account, and cannot be undone.
        <br> <br>
        Please enter your current username to confirm.
      </p>
      <div class="my-5 flex flex-row items-center">
        <label for="username" class="block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[180px]">
          <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Please enter your current username here"/>
          Username:</label>
        <input id="username" name="username" type="email" v-model="username"
               class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6">
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-red-500 text-sm mt-2">{{ globalError }}</div>
      <div class="text-end">
        <button type="button" @click="deleteAccount" :disabled="currentUsername !== username"
                class="text-white px-8 py-2 rounded bg-red-500 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50 disabled:brightness-50">
          Delete Account
        </button>
        <button @click="$emit('close')" type="reset"
                class="text-white px-8 py-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
          close
        </button>
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import {computed, defineComponent, ref} from 'vue';
import ModalComponent from "@/components/Modals/ModalComponent.vue";
import {useStore} from "vuex";

export default defineComponent({
  name: "ConfirmationModal",
  components: {ModalComponent},
  props: {
    isModalOpen: Boolean,
  },
  setup() {
    const store = useStore();
    const username = ref('');
    const currentUsername = computed(() => store.state.authentication.userMetadata.username);

    const deleteAccount = () => {
      store.dispatch('authentication/deleteAccount', username.value)
        .then(() => {
          store.dispatch('authentication/firebaseSignOut');
        })
    }

    return {
      deleteAccount,
      currentUsername,
      username,
    }
  }
});
</script>