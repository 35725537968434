<template>
  <ModalComponent class="w-36" :is-open="isModalOpen" @close="$emit('close')">
    <template v-slot:header>
      <h1 class="w-full text-center text-5xl">{{ header }}</h1>
    </template>
    <template v-slot:content>
      <div class="flex flex-col items-center">
        <p class="text-2xl">{{ body }}</p>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-end">
        <button @click="$emit('confirmed')" class="text-white px-8 py-2 rounded bg-primary m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
           {{ confirmButtonText }}
        </button>
        <button @click="$emit('close')" type="reset"
                class="text-white px-8 py-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
          close
        </button>
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import {defineComponent} from 'vue';
import ModalComponent from "@/components/Modals/ModalComponent.vue";

export default defineComponent({
  name: "ConfirmationModal",
  components: {ModalComponent},
  props: {
    isModalOpen: Boolean,
    header: {
      type: String,
      default: 'Confirm'
    },
    body: {
      type: String,
      default: 'Are you sure you want to complete this action?'
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm'
    },
  },
});
</script>