<template>
  <div class="m-0 text-center w-full z-10">
    <div class="Dashboard__account-cover-photo bg-marble w-full border-4 border-primary rounded-lg p-0 m-0 relative">
      <img class="h-[300px] max-w-full object-cover m-auto"
           :src="props.userMetadataProp.coverURL ? props.userMetadataProp.coverURL : coverPhotoPlaceholder"
           :alt="`Cover photo for ${props.userMetadataProp.username}`"/>
    </div>

    <div class="Dashboard__account-profile z-20 rounded-full w-full p-0 -mt-32 flex">
      <div class="Dashboard__account-profile-photo-section w-1/4 ml-5 inline-block p-0">
        <div class="Dashboard__account-profile-photo-container relative">
          <img class="ProfilePicture bg-marble relative z-20 w-64 h-64 object-cover rounded-full inline-block bg-center border-primary border-4"
               :src="props.userMetadataProp.photoURL ? props.userMetadataProp.photoURL : profilePhotoPlaceholder"
               :alt="`Profile photo for ${props.userMetadataProp.username}`"/>

        </div>
        <!-- Friends List -->
        <p v-if="props.userMetadataProp.isFriendsListPublic" class="FriendsList" @click="$emit('openFriendsTab')">Friends List: {{ friendsListLength() }}</p>
        <!-- Create Wishlist Button -->
        <div v-if="friendStatus === FriendStatusTypes.NOT_FRIEND" @click="addFriend" class="Dashboard__account-add-to-wishlist-btn my-5 bg-primary text-white transition-all duration-200 w-full rounded-md text-xl hover:brightness-90 hover:cursor-pointer hover:text-black active:brightness-85 flex items-center justify-center">
          <font-awesome-icon class="inline align-middle my-0.5" icon="user-plus"/>
          <span class="inline p-1 font-bold">ADD FRIEND</span>
        </div>
        <div v-else-if="friendStatus === FriendStatusTypes.FRIEND" @click="unFriendUser" class="Dashboard__account-add-to-wishlist-btn my-5 bg-primary text-white transition-all duration-200 w-full rounded-md text-xl hover:brightness-90 hover:cursor-pointer hover:text-black active:brightness-85 flex items-center justify-center">
          <font-awesome-icon class="inline align-middle my-0.5" icon="check"/>
          <span class="inline p-1 font-bold">YOUR FRIEND</span>
        </div>
        <div v-else-if="friendStatus === FriendStatusTypes.SENT_REQUEST" @click="unSendFriendRequest" class="Dashboard__account-add-to-wishlist-btn my-5 bg-primary text-white transition-all duration-200 w-full rounded-md text-xl hover:brightness-90 hover:cursor-pointer hover:text-black active:brightness-85 flex items-center justify-center">
          <font-awesome-icon class="inline align-middle my-0.5" icon="user-check"/>
          <span class="inline p-1 font-bold">FRIEND REQUEST SENT</span>
        </div>
        <div v-else-if="friendStatus === FriendStatusTypes.RECEIVED_REQUEST" @click="acceptFriendRequest" class="Dashboard__account-add-to-wishlist-btn my-5 bg-primary text-white transition-all duration-200 w-full rounded-md text-xl hover:brightness-90 hover:cursor-pointer hover:text-black active:brightness-85 flex items-center justify-center">
          <font-awesome-icon class="inline align-middle my-0.5" icon="user-plus"/>
          <span class="inline p-1 font-bold">ACCEPT FRIEND REQUEST</span>
        </div>
      </div>
      <div class="Dashboard__username-section inline-block text-left w-5/8 mr-5 ml-2.5">
        <!-- Username and Real Name -->
        <h1 class="Dashboard__username mt-36 inline-block leading-9">
          <span class="Dashboard__username-display-name text-5xl font-bold text-primary">{{ props.userMetadataProp.username }}<br></span>
          <span class="Dashboard__username-real-name text-3xl font-bold" v-if="props.userMetadataProp.showRealName">{{props.userMetadataProp.firstName}} {{ props.userMetadataProp.lastName }}</span>
        </h1>
        <!-- Birthday and Bio -->
        <div>
          <span v-if="props.userMetadataProp.showBirthday && props.userMetadataProp.birthday">
            <img class="inline w-5 h-5" alt="birthday cake icon" src="../../assets/wishlistIcons/birthdayCake.svg" />
            <span class="text-lg inline ms-2">{{ formattedBirthday() }}</span>
          </span>
        </div>
        <div class="m-0 pt-[10px] text-md">
          <span v-if="props.userMetadataProp.bio">
          {{ props.userMetadataProp.bio }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, ref} from 'vue';
import coverPhotoPlaceholder from '@/assets/coverPhotoPlaceholder.webp';
import profilePhotoPlaceholder from '@/assets/profilePhotoPlaceholder.webp';
import {FriendStatusTypes} from "@/Enums/FriendStatusTypes";
import store from "@/store/store";
import {NotificationTypes} from "@/Enums/NotificationTypes";


export default defineComponent({
  name: "DashboardProfileHeader",
  props: {
    userMetadataProp: {
      type: Object,
      required: true
    },
    hideProfileData: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const profilePhotoHover = ref(false);

    const formattedBirthday = () => {
      try {
        const birthday = props.userMetadataProp.birthday.split('-');
        const date = new Date(birthday[0], birthday[1] - 1, birthday[2]);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
      } catch {
        return ''
      }
    };

    const friendsListLength = () => {
      if (!props.userMetadataProp.friends) {
        return 0
      }
      return props.userMetadataProp.friends.length
    };

    const friendStatus = computed(() => {
      const me = store.state.authentication.userMetadata
      const them = props.userMetadataProp

      if (me.friends.find(friend => friend === them.uid)) {
        return FriendStatusTypes.FRIEND
      }
      // If they have sent a friend request
      if (them.notifications.find(notification => notification.fromUID === me.uid)) {
        return FriendStatusTypes.SENT_REQUEST
      }
      // If the current user has received a friend request
      if (me.notifications.find(notification => notification.fromUID === them.uid)) {
        return FriendStatusTypes.RECEIVED_REQUEST
      }
      return FriendStatusTypes.NOT_FRIEND
    });

    const addFriend = () => {
      const me = store.state.authentication.userMetadata
      const them = props.userMetadataProp
      const name = me.showRealName
          ? `${me.firstName} ${me.lastName} (${me.username})` : me.username;
      const notification = {
        type: NotificationTypes.FRIEND_REQUEST,
        fromUID: me.uid,
        fromUsername: me.username,
        fromName: name,
        fromPhotoURL: me.photoURL,
        toUID: them.uid,
        time: (new Date()).toISOString()
      }
      store.dispatch("authentication/sendFriendRequest", {
        them,
        notification,
      })
    }
    const acceptFriendRequest = () => {
      store.dispatch("authentication/acceptFriendRequest", {
        themUID: props.userMetadataProp.uid
      })
    }
    const unSendFriendRequest = () => {
      store.dispatch("authentication/unSendFriendRequest", {
        them: props.userMetadataProp
      })
    }
    const unFriendUser = () => {
      store.dispatch("authentication/unFriendUser", {
        them: props.userMetadataProp
      })
    }

    return {
      addFriend,
      acceptFriendRequest,
      unSendFriendRequest,
      unFriendUser,
      formattedBirthday,
      friendsListLength,
      FriendStatusTypes,
      friendStatus,
      props,
      profilePhotoHover,
      coverPhotoPlaceholder,
      profilePhotoPlaceholder,
    }
  },
});
</script>

<style lang="scss" scoped>
@import "~@/styles/dashboardProfileHeader.scss";
</style>