class Wishlist {
    constructor() {
        this._id = '';
        this._name = '';
        this._description = '';
        this._date = '';
        this._privacy = '';
        this._theme = '';
        this._owner = '';
        /**
         * @type {import('./Product.js').default[]}
         */
        this._items = [];
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }

    get date() {
        return this._date;
    }

    set date(value) {
        this._date = value;
    }

    get privacy() {
        return this._privacy;
    }

    set privacy(value) {
        this._privacy = value;
    }

    get theme() {
        return this._theme;
    }

    set theme(value) {
        this._theme = value;
    }

    get items() {
        return this._items;
    }

    set items(value) {
        this._items = value;
    }

    get owner() {
        return this._owner;
    }

    set owner(value) {
        this._owner = value;
    }

    toJSON() {
        return {
            id: this._id,
            name: this._name,
            description: this._description,
            date: this._date,
            privacy: this._privacy,
            theme: this._theme,
            items: this._items,
            owner: this._owner
        }
    }
}

module.exports = Wishlist;