<template>
  <div class="m-0 text-center w-full z-10">
    <div class="Dashboard__account-cover-photo bg-marble w-full border-4 border-primary rounded-lg p-0 m-0 relative">
      <img class="h-[300px] max-w-full object-cover m-auto"
           :src="props.userMetadataProp.coverURL ? props.userMetadataProp.coverURL : coverPhotoPlaceholder"
           :alt="`Cover photo for ${props.userMetadataProp.username}`"/>
      <font-awesome-icon title="Edit Cover Photo" class="absolute bottom-1 right-1 text-4xl text-primary z-30 active:brightness-90 hover:brightness-95 hover:cursor-pointer"
                         @click="$emit('openUploadCoverPictureModal')"
                         icon="pen-square"/>
    </div>

    <div class="Dashboard__account-profile z-20 rounded-full w-full p-0 -mt-32 flex">
      <div class="Dashboard__account-profile-photo-section w-1/4 ml-5 inline-block p-0">
        <div class="Dashboard__account-profile-photo-container relative"
             @mouseover="profilePhotoHover = true"
             @mouseleave="profilePhotoHover = false">
          <font-awesome-icon v-if="!props.userMetadataProp.photoURL" title="Edit Profile Photo" v-show="profilePhotoHover" class="z-30 text-primary text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 active:brightness-90 hover:brightness-95 hover:cursor-pointer"
                             @click="$emit('openUploadProfilePictureModal')"
                             icon="pen-square"/>
          <img class="ProfilePicture bg-marble relative z-20 w-64 h-64 object-cover rounded-full inline-block bg-center border-primary border-4"
               :src="props.userMetadataProp.photoURL ? props.userMetadataProp.photoURL : profilePhotoPlaceholder"
               :alt="`Profile photo for ${props.userMetadataProp.username}`"/>

        </div>
        <p v-if="!props.hideProfileData" class="FriendsList" @click="$emit('openFriendsTab')">Friends List: {{ friendsListLength() }}</p>
        <div v-if="!props.hideProfileData" @click="$emit('openCreateWishlistModal')" class="Dashboard__account-add-to-wishlist-btn my-5 bg-primary text-white transition-all duration-200 w-full rounded-md text-xl hover:brightness-90 hover:cursor-pointer hover:text-black active:brightness-85 flex items-center justify-center">
          <font-awesome-icon class="inline align-middle my-0.5" icon="plus"/>
          <span class="inline p-1 font-bold">CREATE WISHLIST</span>
        </div>
      </div>
      <div v-if="!props.hideProfileData" class="Dashboard__username-section inline-block text-left w-5/8 mr-5 ml-2.5">
        <h1 class="Dashboard__username mt-36 inline-block leading-9">
          <span class="Dashboard__username-display-name text-5xl font-bold text-primary">{{ props.userMetadataProp.username }}<br></span>
          <span class="Dashboard__username-real-name text-3xl font-bold" v-if="props.userMetadataProp.showRealName">{{props.userMetadataProp.firstName}} {{ props.userMetadataProp.lastName }}</span>
        </h1>
        <p v-if="props.userMetadataProp.showBirthday">
          <span v-if="props.userMetadataProp.birthday && props.userMetadataProp.birthday !== ''">
            <img class="inline w-5 h-5" alt="birthday cake icon" src="../../assets/wishlistIcons/birthdayCake.svg" />
            <span class="text-lg inline ms-2">{{ formattedBirthday() }}</span>
          </span>
          <span v-else>
            <router-link class="text-primary hover:underline text-lg" to="/account?tab=general">Add Your Birthday</router-link>
          </span>
        </p>
        <p class="m-0 pt-[10px] text-md">
          <span v-if="props.userMetadataProp.bio">
          {{ props.userMetadataProp.bio }}
          </span>
          <span v-else>
            <router-link class="text-primary hover:underline text-lg" to="/account?tab=general">Add Your Bio</router-link>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import coverPhotoPlaceholder from '@/assets/coverPhotoPlaceholder.webp';
import profilePhotoPlaceholder from '@/assets/profilePhotoPlaceholder.webp';


export default defineComponent({
  name: "DashboardProfileHeader",
  props: {
    userMetadataProp: {
      type: Object,
      required: true
    },
    hideProfileData: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const profilePhotoHover = ref(false);

    const formattedBirthday = () => {
      try {
        const birthday = props.userMetadataProp.birthday.split('-');
        const date = new Date(birthday[0], birthday[1] - 1, birthday[2]);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
      } catch {
        return ''
      }
    };

    const friendsListLength = () => {
      if (!props.userMetadataProp.friends) {
        return 0
      }
      return props.userMetadataProp.friends.length
    };

    return {
      formattedBirthday,
      friendsListLength,
      props,
      profilePhotoHover,
      coverPhotoPlaceholder,
      profilePhotoPlaceholder,
    }
  },
});
</script>

<style lang="scss" scoped>
@import "~@/styles/dashboardProfileHeader.scss";
</style>