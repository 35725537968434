<template>
  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-lg">
      <h2 class="mt-10 pt-5 pb-5 text-center text-2xl font-bold tracking-tight text-white bg-primary">Register for an
        account</h2>
    </div>

    <div class="relative mt-5 sm:mx-auto sm:w-full sm:max-w-lg">
      <LoadingComponent v-if="isLoading"/>
      <OAuthSignInSlot/>

      <hr class="mt-5 mb-5"/>

      <Form @submit="handleSubmit" class="grid grid-cols-2 gap-4">

        <!-- First name -->
        <div>
          <Field name="firstName" :rules="{ required: true, max: constants.FIRST_NAME_MAX_LENGTH }"
                 v-slot="{ field, errors }">
            <label for="firstName" class="block text-sm font-medium leading-6 text-gray-900">First Name:</label>
            <input v-bind="field" id="firstName" name="firstName" type="text"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>
        </div>

        <!-- Last name -->
        <div>
          <Field name="lastName" :rules="{ required: true, max: constants.LAST_NAME_MAX_LENGTH }"
                 v-slot="{ field, errors }">
            <label for="lastName" class="block text-sm font-medium leading-6 text-gray-900">Last Name:</label>
            <input v-bind="field" id="lastName" name="lastName" type="text"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>
        </div>

        <!-- Username -->
        <div>
          <Field name="username" :rules="{ required: true, max: constants.USERNAME_MAX_LENGTH, noSpaces: true}"
                 v-slot="{ field, errors }">
            <label for="username" class="block text-sm font-medium leading-6 text-gray-900">Username:</label>
            <input v-bind="field" id="username" name="username" type="text"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>
        </div>

        <!-- Email -->
        <div>
          <Field name="email" :rules="{ required: true, email: true }" v-slot="{ field, errors }">
            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email:</label>
            <input v-bind="field" id="email" name="email" type="email"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>
        </div>

        <!-- Birthday -->
        <div class="col-span-2">
          <Field name="birthday" :rules="{ required: true }" v-slot="{ field, errors }">
            <label for="birthday" class="block text-sm font-medium leading-6 text-gray-900">Birthday:</label>
            <input v-bind="field" id="birthday" name="birthday" type="date"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>
        </div>

        <!-- Password -->
        <div>
          <Field name="password" :rules="{ required: true, hasSpecialChar: true, hasNumber: true, min: 8 }"
                 v-slot="{ field, errors }">
            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password:</label>
            <input v-bind="field" id="password" name="password" type="password" ref="password"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>
        </div>

        <!-- Confirm Password -->
        <div>
          <Field name="confirmPassword" :rules="{ required: true, confirmed: 'password'}"
                 v-slot="{ field, errors }">
            <label for="confirmPassword" class="block text-sm font-medium leading-6 text-gray-900">Confirm
              Password:</label>
            <input v-bind="field" id="confirmPassword" name="confirmPassword" type="password"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>
        </div>

        <div class="col-span-2 text-center">
          <div class="text-red-500 text-sm mt-2">{{ error }}</div>
          <button
              class="w-[60%] inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5 active:brightness-90"
              type="submit">Register
          </button>
          <div>
            If you already have an account, please
            <router-link to="/login"
                         class="inline-block px-2 py-1 text-xs font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg hover:bg-primary focus:outline-none mt-5">
              Login
            </router-link>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'
import {defineComponent} from 'vue'
import OAuthSignInSlot from "@/components/Authentication/OAuthSignIn.vue";
import {mapState} from "vuex";
import {AuthActions} from "@/Enums/AuthActions";
import UserMetadata from "@/entities/UserMetadata";
import LoadingComponent from "@/components/LoadingComponent.vue";
import store from "@/store/store";
import * as constants from "@/constants";
import {camelCaseToSentence, fieldCompare, hasNumber, hasSpecialChar, noSpaces} from "@/plugins/veeValidateHelperMethods";

import {Form, Field, defineRule, configure} from 'vee-validate';
import {required, email, min, max} from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('confirmed', fieldCompare);
defineRule('hasSpecialChar', hasSpecialChar);
defineRule('hasNumber', hasNumber);
defineRule('noSpaces', noSpaces);

configure({
  validateOnInput: true,
  generateMessage: (context) => {
    const fieldName = camelCaseToSentence(context.field);
    const messages = {
      required: `${fieldName} is required`,
      email: `Please enter a valid email`,
      min: `${fieldName} must be at least characters ${context.rule.params[0]}`,
      max: `${fieldName} must be less than characters ${context.rule.params[0]}`,
      confirmed: `${fieldName} does not match`,
      hasSpecialChar: `${fieldName} must contain a special character`,
      hasNumber: `${fieldName} must contain a number`,
      noSpaces: `${fieldName} must not contain spaces`
    };
    return messages[context.rule.name] ?? `The ${fieldName} is invalid`;
  },
});

export default defineComponent({
  name: 'RegisterPage',
  components: {LoadingComponent, OAuthSignInSlot, Field, Form},
  computed: {
    ...mapState('authentication', ['error'])
  },
  setup() {
    const isLoading = computed(() => store.state.authentication.isLoading)
    const handleSubmit = (event) => {
      const user = new UserMetadata()
      user.firstName = event.firstName
      user.lastName = event.lastName
      user.username = event.username
      user.email = event.email
      user.birthday = event.birthday

      store.dispatch("authentication/firebaseAuthenticationHandler", {
        action: AuthActions.CREATE_USER_WITH_EMAIL_AND_PASSWORD,
        params: {
          password: event.password,
          user
        }
      });
    }
    return {
      handleSubmit,
      constants,
      isLoading
    }
  },
})
</script>