<template>
  <Form class="gap-4 space-y-12" @submit="onSubmit">
    <ModalComponent class="w-36" :is-open="isModalOpen" @close="$emit('close')">
      <template v-slot:header>
        <h1 class="w-full text-center text-5xl">Change Email</h1>
      </template>
      <template v-slot:content>
        <!-- Old Email -->
        <Field name="oldEmail" :rules="{ required: true, email: true }" v-slot="{ field, errors }">
          <div class="my-5 flex flex-row items-center">
            <label for="oldEmail" class="block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[180px]">
              <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Enter your current email here"/>
              Old Email:</label>
            <input v-bind="field" id="oldEmail" name="oldEmail" type="email"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6">
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-md mt-2">{{ error }}</div>
          </div>
        </Field>

        <!-- New Email -->
        <Field name="newEmail" :rules="{ required: true, email: true }" v-slot="{ field, errors }">
          <div class="my-5 flex flex-row items-center">
            <label for="newEmail" class=" whitespace-nowrap block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[180px]">
              <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Enter your new email here"/>
              New Email:</label>
            <input v-bind="field" id="newEmail" name="newEmail" type="email"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6">
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-md mt-2">{{ error }}</div>
          </div>
        </Field>

        <!-- Confirm New Email -->
        <Field name="confirmNewEmail" :rules="{ required: true, confirmed: 'newEmail', email: true }" v-slot="{ field, errors }">
          <div class="my-5 flex flex-row items-center">
            <label for="confirmNewEmail" class="block text-md font-medium leading-6 text-gray-900 mr-2 min-w-[180px]">
              <font-awesome-icon class="text-lg mr-2" icon="question-circle" title="Confirm your new email here"/>
              Confirm Email:</label>
            <input v-bind="field" id="confirmNewEmail" name="confirmNewEmail" type="email"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6">
          </div>
          <div class="input-errors" v-for="error in errors" :key="error">
            <div class="text-red-500 text-md mt-2">{{ error }}</div>
          </div>
        </Field>

      </template>
      <template v-slot:footer>
        <div class="text-red-500 text-sm mt-2">{{ globalError }}</div>
        <div class="text-end">
          <button type="submit"
                  class="text-white px-8 py-2 rounded bg-primary m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
            Update Email
          </button>
          <button @click="$emit('close')" type="reset"
                  class="text-white px-8 py-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
            close
          </button>
        </div>
      </template>
    </ModalComponent>
  </Form>
</template>

<script>
import {computed, defineComponent, ref} from 'vue';
import ModalComponent from "@/components/Modals/ModalComponent.vue";
import {configure, defineRule, Field, Form} from "vee-validate";
import {camelCaseToSentence, fieldCompare} from "@/plugins/veeValidateHelperMethods";
import {email, required} from "@vee-validate/rules";
import {getAuth, updateEmail} from "firebase/auth";
import {useStore} from "vuex";
import {authErrors} from "@/plugins/errorMessageHelper";

defineRule('required', required);
defineRule('email', email);
defineRule('confirmed', fieldCompare);

configure({
  validateOnInput: true,
  generateMessage: (context) => {
    const fieldName = camelCaseToSentence(context.field);
    const messages = {
      required: `${fieldName} is required`,
      email: `Please enter a valid email`,
      confirmed: `${fieldName} does not match`,
    };
    return messages[context.rule.name] ?? `The ${fieldName} is invalid`;
  },
});

export default defineComponent({
  name: "ConfirmationModal",
  components: {Field, Form, ModalComponent},
  props: {
    isModalOpen: Boolean,
  },
  setup(props, context) {
    const globalError = ref("");
    const store = useStore()
    const currentEmail = computed(() => store.state.authentication.userMetadata.email)
    const onSubmit = (values) => {
      if (currentEmail.value !== values.oldEmail) {
        globalError.value = "The old email does not match the current email."
        return
      }
      updateEmail(getAuth().currentUser, values.newEmail).then(() => {
        globalError.value = ""
        context.emit('close')
        store.dispatch("authentication/changeEmail", {newEmail: values.newEmail})
      }).catch((error) => {
        globalError.value = authErrors[error.code.substring(error.code.indexOf('/') + 1)];
      })
    }

    return {
      onSubmit,
      globalError
    }
  }
});
</script>