<template>
  <div class="relative">
    <ShareWishlistModal :index="index" :wishlist="wishlist" :isModalOpen="isShareModalOpen" @close="isShareModalOpen = false"/>
    <div class="h-[100%] text-yellow-500 bg-white font-bold border-primary border-2 rounded-lg text-center p-4 relative inline-block hover:cursor-pointer"
         @mouseover="hoverActive = true"
         @mouseleave="hoverActive = false">
      <div v-show="hoverActive" class="absolute inset-0 m-auto h-auto top-0 bottom-0 left-0 right-0 text-center z-10 flex flex-col justify-center">
        <div v-if="mode === WishlistCardTypes.EDIT" class="flex flex-col justify-center">
          <button @click="shareWishlist" class="text-white p-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
            SHARE WISHLIST
          </button>
          <router-link :to="`/wishlist/${index + 1}`" class="text-white p-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
            EDIT WISHLIST
          </router-link>
        </div>
        <div v-if="mode === WishlistCardTypes.VIEW">
          <router-link :to="`/wishlist/${username}/${index + 1}`" v-if="mode === 'View'" class="text-white p-2 rounded bg-gray-400 m-4 text-lg hover:brightness-90 hover:cursor-pointer active:brightness-50">
            VIEW WISHLIST
          </router-link>
        </div>
      </div>
      <img v-if="wishlist.theme.name" :src="require(`../../assets/wishlistIcons/${wishlist.theme.name}.svg`)" alt="Wishlist icon" class="mt-1 w-11/12 inline"/>
      <div class="break-words h-1/4 w-full table text-2xl">
        <div class="table-cell">
          {{ wishlist.name }}
        </div>
      </div>
    </div>
    <div v-show="hoverActive" class="absolute rounded-lg p-d inset-0 bg-black opacity-50 z-5"></div>
  </div>
</template>

<script>
import {defineComponent,ref} from "vue";
import {WishlistCardTypes} from "@/Enums/WishlistCardTypes";
import ShareWishlistModal from "@/components/Modals/ShareWishlistModal.vue";

export default defineComponent({
  name: "WishlistCard",
  components: {ShareWishlistModal},
  props: {
    wishlist: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: 'Edit'
    },
    index: {
      type: Number,
      required: true
    },
    username: {
      type: String,
      required: false
    }
  },
  setup() {
    const hoverActive = ref(false);
    const isShareModalOpen = ref(false);

    const shareWishlist = () => {
      isShareModalOpen.value = true
    };

    return {
      WishlistCardTypes,
      shareWishlist,
      isShareModalOpen,
      hoverActive
    };
  },
})
</script>


<style scoped lang="scss">

</style>