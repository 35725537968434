<template>
  <div>
    <Form class="gap-4" @submit="onSubmit">
      <ModalComponent :isOpen="props.isModalOpen" @close="$emit('close')" @submit="$emit('close')" name="first-modal">
        <template #header>
          <div class="flex justify-between">
            <h1 class="text-4xl font-bold md:text-2xl">Create Wishlist</h1>
            <img class="w-10 h-10 cursor-pointer md:w-8 md:h-8" alt="Close Modal" src="../../assets/goldCross.webp"
                 @click="$emit('close')"/>
          </div>
        </template>
        <template #content>

          <!-- Wishlist Name -->
          <Field name="name" :rules="{ required: true, max: 50 }" v-slot="{ field, errors }">
            <label for="name" class="block text-sm font-medium leading-6 text-gray-900 pt-5">Wishlist Name:</label>
            <input v-bind="field" id="name" name="name" type="text" placeholder="Enter wishlist name"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>


          <!-- Wishlist Date -->
          <Field name="date" :rules="{ required: false }" v-slot="{ field, errors }">
            <label for="date" class="block text-sm font-medium leading-6 text-gray-900 pt-5">Date:</label>
            <input v-bind="field" id="date" name="date" type="date"
                   class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                   ref="dateInput"
                   @focus="openDatePicker">
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>

          <!-- Wishlist Privacy -->
          <Field name="privacy" :rules="{ required: true }" v-slot="{ field, errors }">
            <label for="privacy" class="block text-sm font-medium leading-6 text-gray-900 pt-5">Privacy:</label>
            <select v-bind="field" id="privacy" name="privacy"
                    class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <option v-for="privacyType in privacyTypes" :key="privacyType" :value="privacyType">{{ privacyType }}</option>
            </select>
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>


          <!-- Wishlist Description -->
          <Field name="description" :rules="{ required: false, max: constants.WISHLIST_DESCRIPTION_MAX_LENGTH }" v-slot="{ field, errors }">
            <label for="description" class="block text-sm font-medium leading-6 text-gray-900 pt-5">Description:</label>
            <textarea v-bind="field" id="description" name="description" placeholder="Wishlist description"
                      class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>

          <!-- Wishlist Theme -->
          <Field name="theme" v-slot="{ errors }">
            <label for="theme" class="block text-sm font-medium leading-6 text-gray-900 pt-5">Theme:</label>
            <div class="flex items-center">
              <select v-model="selectedTheme" id="theme" name="theme"
                      class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option v-for="theme in themes" :key="theme.name" :value="theme">{{ theme.text }}</option>
              </select>
              <img class="border-primary border-2 ms-2 rounded w-16 h-12 object-contain inline-block p-1 py-1.5"
                   :src="require(`../../assets/wishlistIcons/${selectedTheme.name}.svg`)"
                   alt="Selected Icon"/>
            </div>
            <div class="input-errors" v-for="error in errors" :key="error">
              <div class="text-red-500 text-sm mt-2">{{ error }}</div>
            </div>
          </Field>

        </template>
        <template #footer>
          <div class="flex justify-end mt-4">
            <button class="px-4 py-2 bg-primary text-white rounded mr-2 md:px-2 md:py-1" type="submit">SAVE</button>
            <button class="px-4 py-2 bg-primary text-black rounded md:px-2 md:py-1" @click="$emit('close')">CANCEL</button>
          </div>
        </template>
      </ModalComponent>
    </Form>
  </div>
</template>

<script>
import ModalComponent from "@/components/Modals/ModalComponent.vue";
import {defineComponent, ref} from "vue";
import * as constants from "@/constants";
import {WishlistPrivacyTypes} from "@/Enums/WishlistPrivacyTypes";
import Wishlist from "@/entities/Wishlist";
import {themeTypes} from "@/Enums/ThemeTypes";
import {Form, Field, defineRule, configure} from 'vee-validate';
import {required, max} from '@vee-validate/rules';
import {camelCaseToSentence} from "@/plugins/veeValidateHelperMethods";
import {useStore} from "vuex";

defineRule('required', required);
defineRule('max', max);

configure({
  validateOnInput: true,
  generateMessage: (context) => {
    const fieldName = camelCaseToSentence(context.field);
    const messages = {
      required: `${fieldName} is required`,
      max: `${fieldName} must be less than characters ${context.rule.params[0]}`,
    };

    return messages[context.rule.name] ?? `The field is invalid`;
  }
});

export default defineComponent({
  name: "CreateWishlistModal",
  components: {
    ModalComponent, Form, Field
  },
  props: {
    isModalOpen: Boolean
  },
  setup(props, context) {
    const store = useStore()
    const privacyTypes = ref(Object.values(WishlistPrivacyTypes))
    const themes = ref(themeTypes)
    const dateInput = ref(null);
    const selectedTheme = ref(themes.value[0])

    const onSubmit = (event) => {
      const wishlist = new Wishlist();
      wishlist.name = event.name;
      wishlist.date = event.date ?? null;
      wishlist.description = event.description ?? '';
      wishlist.privacy = event.privacy;
      wishlist.theme = selectedTheme.value;
      wishlist.owner = store.state.authentication.userMetadata.uid;
      store.dispatch('wishlist/createWishlist', wishlist)
      context.emit('close')
    }

    const openDatePicker = () => {
      dateInput.value.showPicker();
    };

    return {
      onSubmit,
      openDatePicker,
      dateInput,
      selectedTheme,
      constants,
      themes,
      privacyTypes,
      props
    }
  }
})
</script>