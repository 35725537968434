<template>
  <div v-if="wishlist" class="flex flex-col md:flex-row">
    <ProductModal :wishlist="wishlist" :mode="ProductModalTypes.ADD" :is-modal-open="isAddItemModalOpen" @close="isAddItemModalOpen = false"/>
    <WishlistSidebar :wishlist-prop="wishlist" v-if="wishlist" @openAddItemModal="isAddItemModalOpen = true" />
    <div v-if="items.length !== 0" class="w-full md:w-3/4 p-4 grid grid-cols-1 md:grid-cols-3 gap-10 h-fit">
      <ProductCard v-for="(product, index) in items" :key="`product-${index}-${state.renderKey}`" :product="product" :wishlist="wishlist" :mode="ProductModalTypes.EDIT" @editProduct="editProduct(product)" @deleteProduct="deleteProduct"/>
    </div>
    <div v-else class="w-full md:w-3/4 p-4 h-fit flex flex-col items-center justify-center space-y-4">
      <h1 class="text-2xl font-bold text-gray-700">No products found</h1>
      <p class="text-lg text-gray-500">You haven't added any products to your wishlist yet. Start exploring and add some!</p>
      <button @click="isAddItemModalOpen = true"  class="px-4 py-2 bg-primary text-white rounded hover:brightness-75">Add product</button>
    </div>
  </div>
  <div v-else>
    <h1>No wishlist found</h1>
  </div>
</template>

<script>
import {ProductModalTypes} from "@/Enums/ProductModalTypes";
import {computed, defineComponent, reactive, ref} from 'vue';
import { useRoute } from 'vue-router'
import store from "@/store/store";
import WishlistSidebar from "@/views/EditWishlist/WishlistSidebar.vue";
import ProductCard from "@/components/Product/ProductCard.vue";
import ProductModal from "@/components/Modals/ProductModal.vue";

export default defineComponent({
  name: "EditWishlistPage",
  components: {ProductModal, ProductCard, WishlistSidebar},
  setup() {
    const route = useRoute();
    const isAddItemModalOpen = ref(false);
    const wishlistIndex = route.params.id - 1;
    const wishlist = computed(() => store.state.wishlist.wishlistMetadata.wishlists[wishlistIndex]);

    const items = computed(() => {
      return wishlist.value.items;
    });

    const state = reactive({
      renderKey: 0
    })

    const editProduct = () => {
      state.renderKey++
    }

    const deleteProduct = ({wishlistID, productID}) => {
      store.dispatch('wishlist/deleteProductFromWishlist', {wishlistID, productID});
      state.renderKey++
    }

    return {
      ProductModalTypes,
      editProduct,
      deleteProduct,
      isAddItemModalOpen,
      state,
      wishlist,
      items
    }
  }
})
</script>
